import foto from "../../../assets/semilleros/mujeres/mujeres.png";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Mujeres: React.FC = () => {
  const Notice1 = `El informe de ONU Mujeres sobre la igualdad de género en el mundo es profundamente preocupante y refleja una realidad desalentadora. Las cifras presentadas nos llaman urgentemente a la acción y a una reflexión crítica sobre el estado actual de la igualdad de género en nuestra sociedad.

  Es inaceptable que más de 340 millones de mujeres y niñas vivirán en pobreza extrema para 2030. Además, el informe destaca la persistencia de la brecha de género en el ámbito laboral y en el trabajo no remunerado en el hogar, lo que subraya la falta de avances significativos en esta área crucial (Naciones Unidas, 2023).
  
  La relación entre género y cambio climático también es una preocupación creciente. El impacto desproporcionado del cambio climático en las mujeres y niñas agrava aún más su situación económica y social. La falta de acceso de las mujeres mayores a pensiones y la violencia de pareja son problemas graves que exigen atención inmediata y soluciones efectivas.
  
  Además, el informe revela que millones de niñas y mujeres jóvenes estarán sin escolarizar en 2030, lo que socava sus oportunidades de desarrollo y empoderamiento. La necesidad de una inversión adicional de 360,000 millones de dólares al año para lograr la igualdad de género y empoderamiento de las mujeres destaca la magnitud del desafío que enfrentamos (Naciones Unidas, 2023).
  
  La falta de priorización de la igualdad de género pone en peligro la Agenda 2030 para el Desarrollo Sostenible en su conjunto. Este informe es una llamada de atención que no podemos ignorar. Nos enfrentamos a una crisis de desigualdad de género que tiene consecuencias profundamente negativas para mujeres y niñas en todo el mundo.
  
  Desde el comité de mujeres de la campaña "El Cambio es Contigo", hacemos un llamado a la acción colectiva, un compromiso sostenido y a soluciones innovadoras para abordar estos problemas de manera efectiva y lograr un mundo donde la igualdad de género sea una realidad, no un objetivo esquivo.
  
  Referencia: Naciones Unidas (2023). "El mundo está fallando a las niñas y las mujeres, según un nuevo informe de las Naciones Unidas."`;
  const Notice2 = `La investigación liderada por el profesor José Fernando Serrano y su equipo en la Universidad de los Andes marca un hito importante hacia una comprensión más profunda de las motivaciones de los hombres que ocupan cargos políticos en Colombia y que abordan cuestiones de género en sus agendas. A lo largo de la historia, las mujeres han desempeñado un papel fundamental como defensoras de las agendas de género a nivel mundial, y su liderazgo ha sido crucial para avanzar en la lucha por la igualdad de género. Sin embargo, es esencial reconocer que la inclusión activa de los hombres en esta área es igualmente crucial para lograr un cambio sostenible y significativo (Universidad de los Andes, 2023).

  El objetivo de este estudio es arrojar luz sobre las diversas motivaciones que impulsan a los hombres políticos a comprometerse con temas de género. Estas motivaciones pueden ser variadas y van desde estrategias políticas que buscan asegurar una parte del electorado hasta un genuino compromiso con la igualdad de género. Comprender estas motivaciones es esencial para diseñar estrategias efectivas que promuevan la equidad de género en la política y la sociedad en general.
  
  Es importante destacar que este proyecto se lleva a cabo en paralelo con investigaciones similares en Liberia y Malasia, lo que crea una red global de conocimiento y cooperación en la promoción de la igualdad de género (Universidad de los Andes, 2023). Esta colaboración internacional es un paso crucial hacia la creación de un movimiento global que trabaje de manera conjunta para abordar los desafíos relacionados con la equidad de género en diferentes contextos culturales y políticos.
  
  Sin embargo, es fundamental recordar que, aunque la participación activa de los hombres en la promoción de la igualdad de género es importante, la voz y el liderazgo de las mujeres siguen siendo esenciales en la discusión y la toma de decisiones sobre cuestiones de género. El empoderamiento de las mujeres y su acceso a cargos de poder y responsabilidad son elementos fundamentales para lograr un cambio duradero y significativo en la sociedad.
  
  En la campaña “El Cambio es Contigo”, liderada por Rodrigo Salazar como candidato al concejo, se ha destacado un enfoque firme en la igualdad de género. Es importante resaltar que la mayoría del equipo de Rodrigo está compuesto por mujeres empoderadas y fuertes que desempeñan un papel fundamental en la formulación y ejecución de estrategias relacionadas con esta cuestión. Este comité de mujeres no solo respalda a Rodrigo, sino que también trabaja activamente para que en el futuro desde el concejo de la ciudad se haga un efectivo acompañamiento y control político de las políticas públicas relacionadas con las mujeres en Cali. Esto subraya el compromiso real de la campaña con la igualdad de género y demuestra que se están tomando medidas concretas para abordar y promover la equidad en todos los aspectos de la política local.
  
  Referencia: Universidad de los Andes (2023). "¿Qué motiva a los hombres en política a aportar en temas de equidad de género?" Facultad de Ciencias Sociales.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Julio 06 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Informe del panorama de género 2023: desafíos persistentes en la
            búsqueda de la igualdad de género.
          </h1>
          <h5 className={style.Fecha}>Septiembre 11 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
      {/*Noticia Julio 06 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Informe del panorama de género 2023: desafíos persistentes en la
            búsqueda de la igualdad de género.
          </h1>
          <h5 className={style.Fecha}>Septiembre 11 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Mujeres;
