import foto from "../../../assets/semilleros/emprendimiento/emprendimiento.png";
import foto1 from "../../../assets/semilleros/emprendimiento/emp1.jpg";
import ExpandableText from "../TextoExpandible";

import style from "../semilla.module.css";

const Emprendimiento: React.FC = () => {
  const Notice1 = `Ampliación del Plazo para la Convocatoria 'Capital Semilla 2023' en Cali
  La Administración Distrital de Cali está presentando el programa 'Capital Semilla 2023', una oportunidad dirigida a emprendedores, trabajadores informales y MiPymes. Esta convocatoria estará abierta hasta el 13 de agosto de 2023 (Alcaldía de Cali, 2023) y su objetivo es fortalecer tanto técnicamente como financieramente a estos grupos.
  
  Para acceder a los beneficios, los participantes deben seguir un proceso que involucra la inscripción, evaluación, formación y la presentación de un plan de negocios. Los interesados pueden acceder al micrositio en la página web del Fondo Solidario y de Oportunidades, donde encontrarán los términos de referencia y los documentos obligatorios, como el certificado de vecindad y el plan de negocios. Estos términos fueron publicados desde el 28 de julio y se recomienda a los aspirantes leerlos detenidamente para poder participar oportunamente en la convocatoria que se abrirá el 31 de julio.
  
  Esta iniciativa ofrece hasta $8 millones en insumos y cuenta con un presupuesto de $4000 millones destinados a fortalecer negocios generadores de ingresos. Járrinson Martínez Collazos, secretario de Desarrollo Económico Distrital, destaca que esta oportunidad cumple con los caleños al brindarles opciones de crecimiento. El proceso, operado por Hábitat Humano, proporcionará capital semilla en forma de insumos, bienes, productos, equipos o maquinaria, con recursos 100% condonables.
  
  Los interesados en acceder a este beneficio no deben tener antecedentes judiciales ni haber recibido capital semilla en los últimos 18 meses. (Alcaldía de Cali, 2023) La Alcaldía de Cali ha decidido extender el plazo hasta el 13 de agosto para la convocatoria Capital Semilla 2023, con el objetivo de apoyar a emprendedores y pequeños empresarios. Un total de 4 mil millones de pesos serán destinados a 750 empresas caleñas que se inscriban.
  
  Esta ampliación se debe a la solicitud de emprendedores para incrementar las oportunidades disponibles. La convocatoria, que comenzó el 21 de julio y originalmente iba a cerrar el 6 de agosto, también ofrecerá asesorías presenciales para ayudar en el proceso de llenado de documentos. Cabe destacar que Iniciativas de Paz y Oportunidades ha brindado respaldo a unos 150 emprendedores del oeste mediante capital semilla y formación en finanzas y mercadeo (El país, 2023).
  
  Referencias:
  
  Alcaldía de Cali (2023). Pilas: La Alcaldía de Cali amplió el plazo para inscribirse a la convocatoria Capital Semilla 2023.`;
  const Notice2 = `En enero de 2021, Cali obtuvo reconocimiento gracias a programas de aceleración como Acelera Región y Valle Impacta, liderados por la Cámara de Comercio de Cali en colaboración con iNNpulsa Colombia y la Fundación Bolívar Davivienda, respectivamente. A pesar de los desafíos en el entorno emprendedor latinoamericano en 2021, Cali fue la única ciudad colombiana que ascendió en el ranking global de ecosistemas de emprendimiento de StartupBlink, pasando del puesto 16 al 14 en Suramérica (El País, 2022).

  Estos reconocimientos destacan el desarrollo de empresas con alto potencial en la región, aportando innovación y mejoras sustanciales a las condiciones locales. En el Valle del Cauca, opera una red regional de Emprendimiento desde 2009, en colaboración entre entidades públicas y privadas. Su enfoque primordial es estimular una cultura emprendedora y liderar decisiones que impacten en políticas orientadas a fomentar la creación y expansión de empresas.
  
  Actores clave dentro de esta red incluyen la Gobernación del Valle del Cauca, la Alcaldía de Santiago de Cali, la Red Universitaria de Emprendimiento, instituciones financieras, cajas de compensación, la Asociación de Emprendedores de Colombia y las Cámaras de Comercio del Valle del Cauca, representando las redes locales de emprendimiento (El País, 2022).
  
  La región busca promover la cultura emprendedora y liderar decisiones que impulsen la creación y el crecimiento empresarial en colaboración con diversas entidades públicas y privadas. En la campaña "¡El cambio es con vos!", el comité se esfuerza por fomentar y contribuir al espíritu emprendedor de la ciudad, brindando apoyo a través de capacitaciones, talleres y orientación en diversas áreas, como la generación de ideas de negocio, la creación de planes de negocio sólidos y estrategias de marketing (El País, 2022).
  
  Referencias:
  
  El País (2022) "¿Por qué Cali está en el top del impulso al emprendimiento? Estas son las claves."`;
  const Notice3 = `La red de iniciativas productivas y comunitarias por el cambio ha decidido enfocarse en capacitaciones sobre procesos productivos y marca personal para fortalecer sus proyectos sociales. Desde el pasado jueves 15 de junio, el equipo de emprendimiento ha estado llevando a cabo talleres para líderes de iniciativas interesados en conocer y postularse a diferentes fondos de apoyo y financiación. El objetivo principal de estas actividades es proporcionar a los emprendedores las herramientas necesarias para lograr la independencia y sostenibilidad a largo plazo.

  Durante la primera sesión, se presentó el Fondo Emprender del SENA, un fondo que impulsa la creación de empresas y la generación de empleo en el país mediante capital semilla, condonable y reembolsable. Se detallaron los requisitos de esta convocatoria y las fechas de postulación. Además, se explicó sobre ValleINN, otro fondo regional que busca fortalecer a emprendedores y empresarios en distintas etapas de sus proyectos.
  
  El llamado a los emprendedores en esta actividad es a organizarse y estructurar sus proyectos de manera que puedan participar en las convocatorias realizadas por diversas entidades públicas en la región y el país. Elizabeth Beltrán, del barrio Manuela Beltrán, compartió su experiencia con la corporación “Los chicos del barrio”, una iniciativa popular de costura que ha generado 9 empleos y se ha convertido en un emprendimiento autosustentable gracias a las convocatorias de la Alcaldía de Cali. “Después de recibir 2 máquinas de coser, pudimos montar el taller de confección y luego solicitamos un préstamo en el banco agrario para ampliar el negocio”, manifestó.
  
  Este tipo de capacitaciones y acceso a fondos son fundamentales para impulsar el emprendimiento y el desarrollo económico en la región, permitiendo que iniciativas locales como “Los chicos del barrio” crezcan y generen un impacto positivo en la comunidad.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Agosto 12 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Impulso Emprendedor en Cali! Ampliada la Convocatoria 'Capital
            Semilla 2023' para Apoyar a Emprendedores y MiPymes
          </h1>
          <h5 className={style.Fecha}>Agosto 12 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
          <a href=" https://www.elpais.com.co/cali/pilas-la-alcaldia-de-cali-amplio-el-plazo-para-inscribirse-a-la-convocatoria-capital-semilla-2023-0609.html#:~:text=Por%20medio%20de%20la%20l%C3%ADnea,o%20requisitos%20exigidos%20para%20participar ">
            El pais
          </a>
          <a href="https://www.cali.gov.co/desarrolloeconomico/publicaciones/177295/capital-semilla-2023-gran-oportunidad-para-emprendedores-trabajadores-informales-y-mipymes-de-cali/ ">
            Alcaldía de Santiago de Cali
          </a>
        </div>
      </section>
      {/*Noticia Agosto 12 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            El Ascenso Emprendedor de Cali: Destacada en el Top 5 de Impulso
            Empresarial
          </h1>
          <h5 className={style.Fecha}>Agosto 12 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
          <a href="https://www.elpais.com.co/economia/por-que-cali-esta-en-el-top-del-impulso-al-emprendimiento-estas-son-las-claves.html ">
            El País (2022)
          </a>
        </div>
      </section>
      {/*Noticia Junio 30 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Los emprendedores por el cambio se capacitan para un futuro
            auto-sostenible
          </h1>
          <h5 className={style.Fecha}>Junio 30 del 2023</h5>
          <ExpandableText text={Notice3} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Emprendimiento;
