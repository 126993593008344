import style from "./contador.module.css";
import { getStorage, setStorage } from "../../Firebase/firestore";
import { useEffect, useState } from "react";

// interface Props {
//   children: React.ReactNode;
// }

const Contador: React.FC = () => {
  const [visita, setVisita] = useState<number | null>(null);
  useEffect(() => {
    const obtenerVisitas = async () => {
      try {
        const count: number = await getStorage();
        if (visita == null) {
          await setStorage(count + 1);
          setVisita(count + 1);
        }
      } catch (error) {
        console.error("Error al obtener las visitas:", error);
      }
    };
    obtenerVisitas();
  }, [visita]);
  return (
    <main className={style.BoxContador}>
      <div className={style.Contador}>
        <p className={style.Count}>Visita {visita}</p>
      </div>
    </main>
  );
};

export default Contador;
