import style from "../blog.module.css";
import foto from "../../../assets/blog/Abril-22-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana9: React.FC = () => {
  const Notice1 = `Concejal verde y organizaciones ambientales unen esfuerzos para que esta propuesta sea estudiada por el Gobierno Nacional.
  Colombia ha sido epicentro de múltiples eventos a nivel nacional e internacional, por ejemplo, la visita del papa Pablo VI en 1968 en Bogotá se entregaron complejos de vivienda con ese mismo nombre, en ciudades como Cali los Juegos Panamericanos de 1972 dejaron obras de espacios deportivos que hoy en día aún se siguen utilizando.  
  La Conferencia sobre Biodiversidad de Naciones Unidas- COP 16, es un evento de una sola vez en la vida, por su tamaño y por la importancia que tiene para proyectar nuestra ciudad ante el mundo. 
  Es la oportunidad para construir un legado que reafirma nuestro compromiso social con la conservación de los entornos naturales y permita además aportar a la transformación de Cali. Esta hermosa ciudad que según cifras del DANE hoy es hogar de casi 2 millones y 300 mil de personas y requiere habilitar más espacio público efectivo para el goce de los ciudadanos. 
  La Organización Mundial de la Salud (OMS) fijó un indicador óptimo entre 10 m2 y 15 m2 de zonas verdes por habitante, con el fin de que estos mitiguen los impactos generados por la contaminación y cumplan una función de amortiguamiento. En Cali escasamente llegamos a 3 m2 por cada caleño, una situación principalmente crítica en la zona del oriente que es la más densamente poblada, y en la zona de ladera como Siloé, donde no hay disponibilidad de espacio para nuevos equipamientos.
  Cali cuenta con centenares de inmuebles incautados a estructuras delincuenciales principalmente del narcotráfico, que permitirían avanzar en el propósito de nuevos espacios públicos, en especial el lote de 83 mil m2 ubicado en la Calle 5 con 50 (diagonal a Cosmocentro y la estación Unidad Deportiva del MÍO), que por años ha sido objeto de actividades comerciales, sin embargo, dada su situación actual de extinción de dominio es menester que por parte del presidente Gustavo Petro, que a través de la Sociedad de Activos Especiales- SAE, se sume a este proyecto movilizador y apoye la creación del ecoparque Parque de la Paz con la Naturaleza COP16. 
  Este parque permitirá reemplazar un vector de contaminación auditiva como el mencionado establecimiento comercial, por un nuevo bosque urbano que defina un corredor biológico entre los cerros de la ciudad y otras zonas verdes como el Club Tequendama, y el río Cañaveralejo hasta su desembocadura en el canal CVC, y posteriormente el río Cauca. 
  Un proyecto urbanístico que podría impactar directamente al menos a 250.000 personas de las comunas 17,19, 20 y el corregimiento de Navarro; e indirectamente a casi un millón de ciudadanos de toda la ciudad, también, mitigamos las llamadas islas de calor, además de ser espacios para que la ciudadanía se conecte con la naturaleza y descanse de las moles de concreto.
  El parque de la COP16 es una necesidad sentida y una oportunidad única para además resignificar los espacios, construir comunidad, recuperar las memorias del territorio, y convocar a los esfuerzos interinstitucionales en un solo proyecto. Cali se lo merece como una gran legado ambiental que invite a no solo integrar socialmente sino a proteger todo tipo de vida.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="Foto"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Lote donde funcionaba "Carpa la 50" podría transformarse en un gran eco
          parque público
        </h1>
        <h5 className={style.Fecha}>Abril 22 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana9;
