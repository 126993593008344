import foto1 from "../../../assets/semilleros/psicojuridico/psi1.jpg";
import foto2 from "../../../assets/semilleros/psicojuridico/psi2.jpg";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Psicojuridico: React.FC = () => {
  const Notice1 = `La Importancia de Abordar la Salud Mental en las Campañas Políticas
  Reconocer la salud mental como un aspecto fundamental del bienestar de la sociedad es crucial en las campañas políticas. La salud mental influye directamente en la capacidad de las personas para participar de manera activa y efectiva en la vida cívica y política, con un impacto que se extiende desde el individuo hasta la comunidad en general.
  
  Una sociedad que valora y promueve la salud mental está en condiciones de fomentar el desarrollo integral de las personas. Esto abarca no solo el bienestar emocional, sino también áreas clave como la educación, el empleo, el acceso a la atención médica, y otras áreas fundamentales para el crecimiento de la sociedad.
  
  La salud mental influye significativamente en la capacidad de las personas para involucrarse en la toma de decisiones políticas y en la participación cívica. Las personas con buena salud mental están más dispuestas y capacitadas para votar, expresar sus opiniones y participar en debates públicos.
  
  Las campañas políticas pueden ser plataformas efectivas para promover la importancia de destinar recursos adecuados a la atención de la salud mental, así como para respaldar políticas que fomenten un ambiente favorable para la salud mental en la sociedad. Reconocer y abordar los desafíos de salud mental puede mejorar la calidad de vida de las personas, promover una participación cívica más activa y crear un entorno en el que las personas se sientan respaldadas y empoderadas.
  
  Por ello, en la campaña "El cambio es con vos", se brinda asesoría psicológica todos los viernes de 2 a 6 de la tarde, con el objetivo de acompañar a todas las personas que lo requieran. Además, se busca fomentar una sociedad sana, participativa y equitativa a través de una propuesta liderada por el Doctor Rodrigo Salazar.`;

  const Notice2 = `Cada vez más personas encuentran en nuestra campaña un espacio donde pueden tramitar y resolver sus problemas psicológicos y jurídicos. El comité psico-jurídico, comprometido con la comunidad, atendió esta semana casos relacionados con problemas de la adolescencia, familia, derecho constitucional y laboral, contribuyendo a la reducción del sufrimiento emocional y mejorando la calidad de vida de la comunidad.

  Como resultados significativos, se brindó asesoría y acompañamiento, logrando el cumplimiento de un incidente de desacato para la entrega de una silla de ruedas motorizada al señor Guillermo Mejía, de la comuna 14. Además, se orientó a una familia en la reorganización de su sistema familiar, fortaleciendo los vínculos afectivos y remitiéndola a psico-pedagogía.
  
  Asimismo, se atendió a una consultante de la comuna 15, a quien se le brindó asesoría jurídica para iniciar una reclamación de indemnización laboral. Los consultantes agradecieron al equipo psico-jurídico de la campaña "El Cambio es Con Vos" por su apoyo y gestión.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Agosto 22  del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Promoviendo la Salud Mental: Un Pilar de Bienestar Social
          </h1>
          <h5 className={style.Fecha}> Agosto 22 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
      {/*Noticia Junio 30 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto2} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            El consultorio psico jurídico esta con Vos
          </h1>
          <h5 className={style.Fecha}> Junio 30 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Psicojuridico;
