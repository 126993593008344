import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./carrusel.module.css";
import EventoSeptiembre from "../../assets/carrusel/septiembre.png";
import EventoAgosto from "../../assets/carrusel/agosto.png";
import EventoJulio from "../../assets/carrusel/julio.jpg";
import EventoJunio from "../../assets/carrusel/junio.png";
import EventoMayo from "../../assets/carrusel/mayo.jpg";
import EventoAbril from "../../assets/carrusel/abril.jpg";
import EventoMarzo from "../../assets/carrusel/marzo.jpg";
import EventoFebrero from "../../assets/carrusel/febrero.jpg";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

type ArrowProps = {
  onClick: () => void | any;
};

const NextArrow: React.FC<ArrowProps> = (props) => {
  const { onClick } = props;
  return (
    <div className={style.buttond} onClick={onClick}>
      <KeyboardDoubleArrowRightIcon />
    </div>
  );
};

const PrevArrow: React.FC<ArrowProps> = (props) => {
  const { onClick } = props;
  return (
    <div className={style.buttond} onClick={onClick}>
      <KeyboardDoubleArrowLeftIcon />
    </div>
  );
};

const Carrusel: React.FC = () => {
  useEffect(() => {
    const slider = document.querySelector(".slick-slider");
    if (slider) {
      (slider as HTMLElement).style.display = "flex";
      (slider as HTMLElement).style.flexDirection = "row";
      (slider as HTMLElement).style.alignItems = "center";
      (slider as HTMLElement).style.margin = "0 auto";
      (slider as HTMLElement).style.backgroundColor = "#ffffff";
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    fade: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow onClick={() => {}} />,
    prevArrow: <PrevArrow onClick={() => {}} />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          fade: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <main className={style.SliderMaster}>
      <div className={style.TittleEventos}>Eventos</div>
      <Slider {...settings}>
        {/* Febrero */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoFebrero}
              alt="Rodrigo Salazar"
              src={EventoFebrero}
            />
          </div>
          <h1 className={style.TextEvent}>Jornada Nacional por la paz</h1>
          <h5 className={style.FechaEvent}>Fecha: Febrero 21 de 2024</h5>
          <p className={style.TextImg}>
            En el marco de la gran Jornada Nacional por la Paz y la Vida,
            llevada a cabo en el hemiciclo del Concejo de Cali, se desarrollaron
            diversos actos culturales propios de la región pacífica y una
            liturgia en donde se resaltó la necesidad de acciones que logren
            frenar la violencia en Colombia.
          </p>
        </div>
        {/* Marzo */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoMarzo}
              alt="Rodrigo Salazar"
              src={EventoMarzo}
            />
          </div>

          <h1 className={style.TextEvent}>Limpiatón del Río Cali</h1>
          <h5 className={style.FechaEvent}>Fecha: Marzo 17 de 2024</h5>
          <p className={style.TextImg}>
            Con el objetivo de conmemorar en marzo el mes de acción por los
            ríos, junto con el colectivo El Cambio es con Vos, desarrollamos una
            jornada de limpieza en el río Cali, en la que con la valiosa ayuda
            de los asistentes se logró despejar de las orillas un gran número de
            residuos sólidos. <br />
            <br />
            Hicimos un llamado a la Alcaldía de Cali y al DAGMA y fortalecer
            acciones de control y mitigación de residuos sobre nuestros ríos.
          </p>
        </div>
        {/* Abril */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoAbril}
              alt="Rodrigo Salazar"
              src={EventoAbril}
            />
          </div>
          <h1 className={style.TextEvent}>Día Movilización por las Víctimas</h1>
          <h5 className={style.FechaEvent}>Fecha: Abril 09 de 2024</h5>
          <p className={style.TextImg}>
            En el Día Nacional de la Memoria y Solidaridad por las Víctimas,
            junto a diversas organizaciones sociales nos movilizamos en las
            calles por las víctimas, la paz y su reparación, al igual que en
            apoyo a las reformas del cambio.
          </p>
        </div>
        {/* Mayo */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoMayo}
              alt="Rodrigo Salazar"
              src={EventoMayo}
            />
          </div>
          <h1 className={style.TextEvent}>Gran jornada del día del trabajo</h1>
          <h5 className={style.FechaEvent}>Fecha: Mayo 01 de 2024</h5>
          <p className={style.TextImg}>
            Con el #ElCambioEsConVos nos movilizamos en las calles junto a miles
            de trabajadores para seguir apoyando las reformas del cambio del
            gobierno nacional. Toda transformación solo se alcanza si la gente
            las conquista en la calle.
          </p>
        </div>
        {/* Junio */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoJunio}
              alt="Rodrigo Salazar"
              src={EventoJunio}
            />
          </div>
          <h1 className={style.TextEvent}>Visita a vereda el Saladito</h1>
          <h5 className={style.FechaEvent}>Fecha: Junio 30 de 2024</h5>
          <p className={style.TextImg}>
            En el corregimiento El Saladito estuvimos presentes en la primera
            celebración del día del campesino, junto a habitantes y líderes del
            sector. Compartimos una jornada de música, emprendimientos verdes y
            la alegría de la gente.
          </p>
        </div>
        {/* Julio */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoJulio}
              alt="Rodrigo Salazar"
              src={EventoJulio}
            />
          </div>
          <h1 className={style.TextEvent}>Visita Parque Cristo Rey</h1>
          <h5 className={style.FechaEvent}>Fecha: Julio 27 de 2024</h5>
          <p className={style.TextImg}>
            En el marco del cumpleaños 488 de Cali junto al alcalde realizamos
            visita técnica al Parque Integral Cristo Rey, evidéncianos el avance
            de esta magnífica obra movilizadora que sin duda será la uno de los
            puntos turísticos favoritos para propios y visitantes.
          </p>
        </div>
        {/* Agosto */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoAgosto}
              alt="Rodrigo Salazar"
              src={EventoAgosto}
            />
          </div>
          <h1 className={style.TextEvent}>Petronio Álvarez</h1>
          <h5 className={style.FechaEvent}>Fecha: Agosto 14 de 2024</h5>
          <p className={style.TextImg}>
            Al ritmo de la marimba, con los sabores de la gastronomía y las más
            deliciosas bebidas del Pacífico colombiano disfrutamos del
            espectacular festival de música Petronio Alvarez, que demuestra ser
            un espacio de encuentro ciudadano y de reivindicación de la cultura
            afro.
          </p>
        </div>
        {/* Septiembre */}
        <div className={style.Matter}>
          <div className={style.MatterContent}>
            <img
              className={style.EventoSeptiembre}
              alt="Rodrigo Salazar"
              src={EventoSeptiembre}
            />
          </div>
          <h1 className={style.TextEvent}>Visita bulevar de Oriente </h1>
          <h5 className={style.FechaEvent}>Fecha: Septiembre 2 de 2024</h5>
          <p className={style.TextImg}>
            Llegamos al Bulevar de Oriente donde adelantamos una visita para ver
            el estado actual de dicho espacio y determinar si necesita mayor
            mantenimiento por parte de la Administración.
          </p>
        </div>
      </Slider>
    </main>
  );
};

export default Carrusel;
