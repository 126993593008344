import styles from "./firstsocial.module.css";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);
const TwitterEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.TwitterEmbed,
  }))
);
const FacebookEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.FacebookEmbed,
  }))
);

const FirstSocial: React.FC = () => {
  return (
    <main className={styles.BoxSocial}>
      <h1 className={styles.BoxSocialTittle}>Redes Sociales</h1>
      <section className={styles.BoxSocialContain}>
        <div className={styles.BoxSocialInstagram}>
          <Suspense
            fallback={
              <div className="loading-placeholder">Loading...</div>
            }
          >
            <InstagramEmbed
              url="https://www.instagram.com/reel/C_OzTFayrzB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              width={328}
            />
          </Suspense>
          <br />
          <Suspense
            fallback={
              <div className="loading-placeholder">Loading...</div>
            }
          >
            <InstagramEmbed
              url="https://www.instagram.com/reel/C-6OhmRsh54/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
              width={328}
            />
          </Suspense>
        </div>
        <div className={styles.BoxSocialTwitter}>
          <Suspense
            fallback={
              <div className="loading-placeholder">Loading...</div>
            }
          >
            <TwitterEmbed
              url="https://twitter.com/rodrisalazarco/status/1829519385226264847"
              width={328}
            />
          </Suspense>          
          <br />
          <Suspense
            fallback={
              <div className="loading-placeholder">Loading ...</div>
            }
          >
            <TwitterEmbed
              url="https://twitter.com/rodrisalazarco/status/1829223543126798674"
              width={328}
            />
          </Suspense>
        </div>
        <div className={styles.BoxSocialFacebook}>
          <Suspense
            fallback={
              <div className="loading-placeholder">Loading...</div>
            }
          >
            <FacebookEmbed
              url="https://www.facebook.com/rodrisalazarco/posts/pfbid0PraCc6VihrFubnfZ8YE1QpibB12Qs3AQRzm1DfWKgC7MGjJxjgAr5xMNaLbCWxNJl"
              width={350}
            />
          </Suspense>
        </div>
      </section>
    </main>
  );
};

export default FirstSocial;
