import style from "./redes.module.css";
import imgfb from "../../assets/Redes/fb.png";
import imgig from "../../assets/Redes/ig.png";
import imgx from "../../assets/Redes/x.png";
import imgwpcm from "../../assets/Redes/wpcm.png";

const Redes: React.FC = () => {
  const handlefb = () => {
    window.location.replace("https://www.facebook.com/rodrisalazarco/");
  };
  const handleig = () => {
    window.location.replace("https://www.instagram.com/rodrisalazarco/");
  };
  const handletw = () => {
    window.location.replace("https://twitter.com/rodrisalazarco");
  };
  const handlewp = () => {
    window.location.replace("https://whatsapp.com/channel/0029Va0PXbgD38CImaGrUC2f");
  };

  return (
    <main className={style.BoxRedes}>
      <div className={style.BoxRed} onClick={handlefb}>
        <img className={style.ImgRed} src={imgfb} alt="Fb" draggable={false} />
      </div>
      <div className={style.BoxRed} onClick={handleig}>
        <img className={style.ImgRed} src={imgig} alt="Ig" draggable={false} />
      </div>
      <div className={style.BoxRed} onClick={handletw}>
        <img className={style.ImgRed} src={imgx} alt="Twitter" draggable={false} />
      </div>
      <div className={style.BoxRed} onClick={handlewp}>
        <img className={style.ImgRed} src={imgwpcm} alt="WpComunity" draggable={false} />
      </div>
      <div className={style.BoxSigue}>
        Siguenos
      </div>
    </main>
  );
};

export default Redes;
