import foto1 from "../../../assets/semilleros/discapacidad/disc1.jpg";
import foto2 from "../../../assets/semilleros/discapacidad/disc2.jpg";
import foto3 from "../../../assets/semilleros/discapacidad/disc3.jpg";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Discapacidad: React.FC = () => {
  const Notice1 = `
  Claro, aquí tienes el texto reorganizado y mejorado:
  
  La Importancia de la Diversidad Humana en la Construcción de Sociedades Vibrantes
  La diversidad humana juega un papel fundamental en la construcción de sociedades enriquecedoras y vibrantes. En el contexto específico de la ciudad de Cali, la inclusión de personas con discapacidades físicas y mentales adquiere una importancia significativa, ya que contribuye a varios aspectos clave de la vida urbana.
  
  Diversidad Cultural y Social:
  
  La inclusión de personas con discapacidades enriquece el tejido social de Cali, agregando perspectivas únicas y valiosas a la identidad cultural de la ciudad.
  Se crea un ambiente en el que se celebra la singularidad de cada individuo.
  Empatía y Comprensión:
  
  La presencia de personas con discapacidad fomenta un mayor nivel de empatía y comprensión entre los ciudadanos.
  La interacción con personas que enfrentan desafíos diversos promueve la sensibilización y la solidaridad, creando un sentido de comunidad más profundo y conectado.
  Innovación y Creatividad:
  
  La necesidad de adaptar entornos, servicios y tecnologías para satisfacer las diversas necesidades de las personas con discapacidad puede llevar al desarrollo de soluciones innovadoras que beneficien a toda la sociedad.
  Esto genera un impacto positivo en múltiples áreas.
  Accesibilidad Urbana:
  
  La inclusión promueve la creación de entornos urbanos más accesibles y servicios que pueden ser utilizados por todos los ciudadanos, independientemente de sus capacidades.
  Contribuye a construir una ciudad más amigable y funcional para todos, alentando la participación activa en la vida cotidiana.
  Participación Ciudadana y Democracia:
  
  La inclusión de personas con discapacidades en la sociedad caleña promueve una mayor participación ciudadana y una democracia más fuerte.
  Garantizar que todas las voces sean escuchadas y respetadas es esencial para construir una sociedad justa y equitativa para todos sus miembros.
  Por estas razones, apoyamos a Rodrigo Salazar y la campaña "El Cambio es con Vos" en su objetivo de llegar al concejo municipal, buscando garantizar la inclusión y mejorar la calidad de vida para todos los ciudadanos de Cali.`;
  const Notice2 = `Promoviendo la Inclusión y el Liderazgo en una Sociedad Diversa
  En el corazón de la diversidad humana se encuentran las discapacidades, condiciones que impactan el cuerpo o la mente, generando deficiencias que complican ciertas actividades y limitan la interacción con el entorno. Esta realidad resalta la necesidad de una sociedad que fomente la inclusión y el liderazgo progresivo.
  
  La pluralidad de discapacidades engloba una amplia gama de desafíos, desde dificultades en la percepción visual y retos en la movilidad y el control físico, hasta obstáculos para procesar información y tomar decisiones, desafíos en la retención y recuperación de datos, y dificultades en la adquisición de conocimientos y habilidades. Además, existen desafíos emocionales o psicológicos, así como obstáculos en la interacción con los demás.
  
  El término "personas con discapacidades" abarca a un grupo diverso con necesidades diversas. Cada individuo experimenta los efectos de la discapacidad de manera única, subrayando la importancia de la inclusión y otorgar oportunidades a líderes valientes que puedan ocupar posiciones influyentes, como en el concejo municipal.
  
  Abrazar la diversidad, crear oportunidades equitativas y empoderar a quienes enfrentan desafíos esenciales son pasos esenciales hacia una sociedad más inclusiva y justa. Al trabajar juntos, superaremos retos y construiremos un mundo donde cada voz importe y cada logro sea un triunfo colectivo.`;
  const Notice3 = `En Cali, el 8.5% de la población enfrenta algún tipo de discapacidad (El País, 2022). Aunque la sociedad ha venido trabajando para aumentar la accesibilidad y las oportunidades, aún queda un largo camino por recorrer.

  Una de las barreras más notables que enfrentan las personas con discapacidad radica en la falta de acceso en las calles de la ciudad. Los andenes carecen de señalización táctil, hay intersecciones sin rampas adecuadas y los puentes peatonales también presentan desafíos significativos. A pesar de ciertas mejoras realizadas hasta el momento, existe una necesidad apremiante de implementar cambios sustanciales.
  
  En esta línea, apoyamos la candidatura de Rodrigo Salazar al concejo de la ciudad desde el comité de discapacidad, ya que él ha demostrado su compromiso con este grupo poblacional. A pesar de los obstáculos, el panorama de bienestar entre las personas con discapacidad es en su mayoría positivo. Un porcentaje considerable de esta población se encuentra satisfecho con su estado y con su calidad de vida en general.
  
  Programas de apoyo y acompañamiento, como los proporcionados por la Secretaría de Bienestar Social de Cali, han sido implementados para fomentar este sentimiento, aunque queda mucho por hacer en la próxima administración.
  
  Referencias:
  
  El País (2022). La movilidad en la ciudad, la deuda de Cali con la población con discapacidad.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Agosto 28 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Diversidad Humana y Sociedad: La Importancia de la Inclusión en Cali
          </h1>
          <h5 className={style.Fecha}>Agosto 28 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />          
        </div>
      </section>
      {/*Noticia Agosto 25 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto2} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Voces Silenciosas, Almas Valientes: Descifrando las Discapacidades
          </h1>
          <h5 className={style.Fecha}>Agosto 25 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
      {/*Noticia Agosto 22 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto3} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Cali por la accesibilidad para la población con discapacidad
          </h1>
          <h5 className={style.Fecha}>Agosto 22 del 2023</h5>
          <ExpandableText text={Notice3} limit={400} />            
            <a href="https://www.elpais.com.co/cali/el-8-5-de-los-calenos-pertenece-a-la-poblacion-con-discapacidad-que-hace-la-ciudad-por-ellos.html">el pais</a>
        </div>
      </section>
    </main>
  );
};

export default Discapacidad;
