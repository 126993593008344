import Rutas from "./Routes";

function App() {
  return (
    <>
      <Rutas />
    </>
  );
}

export default App;
