import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana12: React.FC = () => {
  const Notice1 = `Durante plenaria en el Concejo de Cali, Rodrigo Salazar cabildante del Partido Alianza Verde, presentó citación de debate de control político a la Secretaría de Gobierno encabezada por Andrés Felipe Stapper. La finalidad de esta convocatoria es discutir temas referentes al Contrato No. 4112.020.26.1.267-2024 suscrito con la sociedad CDS International SAS.

En concordancia con lo anterior y en ejercicio de sus funciones, el concejal Salazar solicitó al organismo responder preguntas relacionadas con el contrato celebrado con esa firma bogotana, cuyo objeto del contrato es “para el acompañamiento y la orientación en materia jurídica especializada al Distrito de Santiago de Cali – Secretaría de Gobierno”, algunas de las dudas planteadas en la proposición fueron el por que se contrató a una firma foránea que no cuenta con la idoneidad necesaria elaborando conceptos en materia administrativa suplantando funciones del Departamento Administrativo de Gestión Jurídica de la Alcaldía de Cali.

“¿Qué tiene el secretario Stapper contra los caleños? contrata por modalidad de prestación de servicios por mas de 200 millones de pesos con una firma bogotana CDS Internacional para acompañamiento en materia jurídica, análisis de riesgos jurídicos, seguimiento de acciones y proposición de planes de manejo, funciones que profesionales en derecho en Cali están capacitados y disponibles para cumplir con dicha finalidad, por eso lo estoy citando a un debate”, argumentó Salazar.

El concejal, además agregó que lo anterior se suma al maltrato de profesionales del derecho que cuentan con alta experiencia y posgrados y, están siendo contratados como técnicos bajo esta administración, por lo que hace un llamado al respeto por el recurso personal, valioso y profesional de Cali y la región, que piden oportunidades laborales.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/C8aQsS5BvLI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          “¿No hay talentos profesionales del derecho en el Distrito de Santiago
          de Cali?” Concejal Rodrigo Salazar convoca a Debate de Control
          Político a la Secretaría de Gobierno
        </h1>
        <h5 className={style.Fecha}>Junio 20 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana12;
