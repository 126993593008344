import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana13: React.FC = () => {
  const Notice1 = `En el Concejo de Santiago de Cali se llevo a cabo la plenaria en donde se debatió el proyecto de acuerdo No. 006 del 2024 por la cual se le otorgan autorizaciones al alcalde de Cali en materia de crédito público, tras un arduo debate en donde se leyeron cada uno de los ítems que componen dicho documento, la curul del concejal del Partido Alianza Verde Rodrigo Salazar votó de manera negativa.
  
Entre las razones por la cual el cabildante dijo NO, porque no hay claridad frente a la denuncia interpuesta por el Concejal Mauricio Zamora, por los posibles contratos que se firmaron por parte del señor alcalde, Alejandro Eder para realizar operaciones de crédito y reperfilar la deuda, se desconoce en el evento de que se hayan firmado dichos contratos si tienen o no efectos jurídicos vinculantes que comprometan el presupuesto del distrito, por tanto, no se sabe, si solamente se están trayendo un proyecto de acuerdo para que el Concejo vote sobre hechos cumplidos, que solo buscan convalidar hechos irregulares comprometiendo la corresponsabilidad del concejo.

Además, no resulta claro si las posibles diferencias en los spreads de las tasas de interés bancarias puedan redundar en beneficio para la ciudad, ni la destinación que se le va a dar a la liberación de recursos que resulte de la renegociación de las deudas.

Para que los cabildantes tuvieran un voto informado y la ciudadania transparecnia en la informacion: “solicité que la señora Directora del Departamento Admnistrativo de Hacienda subiera al atril para dar explicación respecto a las importantes denuncias que se han formulado sobre los presuntos contratos de operación de crédito que ya fueron celebrados con dos entidades de la banca privada, sin la debida autorización del Concejo de Cali, sin embargo, dichas aclaraciones no fueron dadas, ratificando la inconformidad para un voto a favor” , manifestó el cabiladante.

Salazar tambien sostiene que esta administración se ha destacado negativamente por la falta de información verás y oportuna que suministran a este Concejo para poder tomar decisiones debidamente informadas. Esto pese a que uno de los pilares del Plan de Desarrollo es el buen gobierno y la “transparencia”.

Los recursos públicos son sagrados y en este proyecto de acuerdo no justificó en debida forma la necesidad y pertinencia de esta autorización, por lo que es un salto al vacío que en últimas busca legitimar las presuntas irregularidades que ya se consumaron.`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C9lY1Mzt6kM/"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Voto Negativo de Concejal Rodrigo Salazar: Inquietudes sobre
          transparencia y efectos jurídicos para reperfilar la deuda
        </h1>
        <h5 className={style.Fecha}>Julio 19 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana13;
