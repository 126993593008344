import foto from "../../../assets/semilleros/deporte/deporte.png";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Deporte: React.FC = () => {
  const Notice1 = `Los Juegos Panamericanos representan el evento deportivo más destacado a nivel internacional, atrayendo a atletas de toda América. Cada cuatro años, previo a los Juegos Olímpicos de Verano, se reúnen deportistas de diversas naciones del continente en una competición de gran envergadura. Cali, en particular, es parte esencial de esta historia, ya que en 1971 fue anfitriona de la sexta edición de los Juegos Panamericanos.

  Aunque en ese momento la organización enfrentó desafíos debido a la carencia de instalaciones adecuadas, el esfuerzo conjunto encabezado por líderes políticos y deportivos del Valle del Cauca hizo posible llevar a cabo los juegos programados para julio y agosto. "El gobierno de Carlos Lleras Restrepo estableció Coldeportes en 1968, hoy conocido como el Ministerio del Deporte, para brindar apoyo técnico y financiero en la construcción y equipamiento de infraestructuras deportivas" (Ministerio del Deporte, 2019). La ciudad de Cali contó con un respaldo nacional para albergar con éxito los Juegos Panamericanos. Este logro histórico demostró la habilidad del país para organizar eventos deportivos de gran envergadura y dejó una marca indeleble en la memoria de los amantes del deporte en Colombia y en la propia Cali.
  
  Esta historia destaca cómo la gestión política, el apoyo ciudadano y el firme propósito de mejorar las condiciones para la práctica deportiva son esenciales para elevar la posición internacional del país. Algunos deportistas locales se han beneficiado de estas instalaciones y del apoyo brindado. Por esta razón, en la campaña "El cambio es con vos", es crucial votar por Rodrigo Salazar el 29 de octubre, un candidato dispuesto a enriquecer el deporte, la actividad física y la recreación en la ciudad.
  
  Referencias: Ministerio del Deporte (2019). Cali y su historia como sede de los Juegos Panamericanos de 1971.`;
  const Notice2 = `Tips Saludables "El Cambio es con Vos"
  ¿Sabías que?
  Hidratación: En 1974, el libro "Nutrition for Good Health" recomendó que el adulto promedio consuma entre seis y ocho vasos de agua al día. Cabe destacar que cada organismo es diferente y las cantidades varían según la edad, actividades y estilo de vida.
  
  Actividad Física: Incorporar la actividad física en tu día a día contribuye a la reducción de los síntomas de depresión y ansiedad.
  
  Recreación en Familia: Compartir espacios de recreación en familia fortalece los lazos afectivos, desarrolla la confianza entre padres e hijos y ayuda a los niños a fortalecer su seguridad en sí mismos. Te invitamos a jugar con el niño de hoy; el adulto de mañana te lo agradecerá.
  
  Risa: La risa es un mecanismo terapéutico que fomenta la salud mental, activa y reduce la respuesta al estrés y estimula los latidos del corazón. En el reír, está el vivir.
  
  Consejos para una Vida Saludable:
  Planificación: Planifica tus actividades con ejercicios motivantes de acuerdo a tu condición física.
  Regularidad: Practica de forma regular la actividad física que escojas, sin olvidar el disfrute y la diversión.
  Valoración Médica: Realízate una valoración médica previa para conocer tu forma física antes de programar el ejercicio, especialmente si has padecido o padeces alguna enfermedad.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Agosto 28 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Los Juegos Panamericanos: Un Encuentro Deportivo de América
          </h1>
          <h5 className={style.Fecha}>Agosto 28 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />          
            <a href="https://www.mindeporte.gov.co/sala-prensa/noticias-mindeporte/especial-juegos-panamericanos/cali-historia-sede-los-juegos-panamericanos-1971#:~:text=La%20capital%20del%20Valle%20del,m%C3%A1s%20importante%20del%20ciclo%20ol%C3%ADmpico.">
              Ministerios del deporte
            </a>
        </div>
      </section>
      {/*Noticia Julio 05 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Paso a paso con el deporte y la recreación
          </h1>
          <h5 className={style.Fecha}>Julio 05 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Deporte;
