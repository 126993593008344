import { useState } from "react";
import style from "./cards.module.css";
import Img1 from "../../assets/Logo-Alegria.png";
import Img2 from "../../assets/Logo-Compromiso.png";
import Img3 from "../../assets/Logo-Determinacion.png";
import Img4 from "../../assets/Logo-Fe.png";
import { Animated as Animatedx } from "react-animated-css";
import VisibilitySensor from "react-visibility-sensor";

const Cards: React.FC = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  return (
    <main className={style.MasterBox}>
      <div className={style.TittleCards}>
        <h1 className={style.TittleValoresCartas}>Valores</h1>
        <p className={style.SubTittleValoresCartas}>
          Nuestros valores son el motor que nos impulsa a seguir adelante.
        </p>
      </div>
      <section className={style.Cartas}>
        {/* Determinacion */}
        <VisibilitySensor
          partialVisibility
          onChange={(isVisible3: boolean) => setIsVisible3(isVisible3)}
        >
          <Animatedx
            {...{
              animationIn: "flipInY",
              animationOut: "fadeOut",
              animationInDuration: 1000,
              isVisible: isVisible3,
            }}
          >
            <div className={style.Card}>
              <div className={style.CardImg}>
                <img className={style.ImgCard} alt="Determinacion" src={Img3} />
              </div>
              <div className={style.CardText}>
                <h1 className={style.tittle}>Determinación</h1>
                <p className={style.text}>
                  Tengo la determinación para construir, desarrollar y sostener
                  mis ideas a lo largo del tiempo.
                </p>
              </div>
            </div>
          </Animatedx>
        </VisibilitySensor>
        {/* Alegria */}
        <VisibilitySensor
          partialVisibility
          onChange={(isVisible1: boolean) => setIsVisible1(isVisible1)}
        >
          <Animatedx
            {...{
              animationIn: "flipInY",
              animationOut: "fadeOut",
              animationInDuration: 1000,
              isVisible: isVisible1,
            }}
          >
            <div className={style.Card}>
              <div className={style.CardImg}>
                <img className={style.ImgCard} alt="Alegria" src={Img1} />
              </div>
              <div className={style.CardText}>
                <h1 className={style.tittle}>Alegría</h1>
                <p className={style.text}>
                  Con alegría me mantengo firme para sobrepasar cualquier
                  obstaculo que se me presente.
                </p>
              </div>
            </div>
          </Animatedx>
        </VisibilitySensor>
        {/* Compromiso */}
        <VisibilitySensor
          partialVisibility
          onChange={(isVisible2: boolean) => setIsVisible2(isVisible2)}
        >
          <Animatedx
            {...{
              animationIn: "flipInY",
              animationOut: "fadeOut",
              animationInDuration: 1000,
              isVisible: isVisible2,
            }}
          >
            <div className={style.Card}>
              <div className={style.CardImg}>
                <img className={style.ImgCard} alt="Compromiso" src={Img2} />
              </div>
              <div className={style.CardText}>
                <h1 className={style.tittle}>Compromiso</h1>
                <p className={style.text}>
                  Me he comprometido a proteger y preservar nuestro entorno
                  natural, dedicando mis esfuerzos hacia un mundo sostenible
                </p>
              </div>
            </div>
          </Animatedx>
        </VisibilitySensor>
        {/*FE */}
        <VisibilitySensor
          partialVisibility
          onChange={(isVisible4: boolean) => setIsVisible4(isVisible4)}
        >
          <Animatedx
            {...{
              animationIn: "flipInY",
              animationOut: "fadeOut",
              animationInDuration: 1000,
              isVisible: isVisible4,
            }}
          >
            <div className={style.Card}>
              <div className={style.CardImg}>
                <img className={style.ImgCard} alt="Fe" src={Img4} />
              </div>
              <div className={style.CardText}>
                <h1 className={style.tittle}>Fe</h1>
                <p className={style.text}>
                  Con fe, creemos en un mundo pacífico y ambientalmente
                  equilibrado para las generaciones futuras.
                </p>
              </div>
            </div>
          </Animatedx>
        </VisibilitySensor>
      </section>
    </main>
  );
};

export default Cards;
