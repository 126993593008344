import { useState } from "react";
import style from "./semilla.module.css";
import AdultoMayor from "./Adultomayor";
import Ambiental from "./Ambiental";
import Cultura from "./Cultura";
import Deporte from "./Deporte";
import Discapacidad from "./Discapacidad";
import Emprendimiento from "./Emprendimiento";
import Etnias from "./Etnias";
import Interreligioso from "./Interreligioso";
import Juventud from "./Juventud";
import Mujeres from "./Mujeres";
import Psicojuridico from "./Psicojuridico";

const Semilla: React.FC = () => {
  const [isSemillero, setSemillero] = useState(0);
  //https://www.instagram.com/p/C25b0ygATVB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==
  /*
  0 = Todas
  1 = Ambiental
  2 = Cultura
  3 = Deporte
  4 = Emprendimiento
  5 = Paz y DDHH
  6 = Poblaciones
  7 = Psicojurídico
  */
  return (
    <main className={style.MasterBoxContainerSemilla}>
      <div className={style.MenuButtonsSemilla}>        
        <button className={style.BotonSemilla} onClick={() => setSemillero(1)}>
          Ambiental
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(2)}>
          Cultura
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(3)}>
          Deporte
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(4)}>
          Emprendimiento
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(5)}>
          Paz y DDHH
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(6)}>
          Poblaciones
        </button>
        <button className={style.BotonSemilla} onClick={() => setSemillero(7)}>
          Psicojurídico
        </button>
      </div>
      <section className={style.MasterBoxSemilla}>
        <div className={style.BannerSemilla}>
          <div className={style.TituloSemilla}>Semilleros</div>
        </div>
      </section>
      <section className={style.MasterBoxSemilleros}>
        {(isSemillero === 0 || isSemillero === 1) && <Ambiental />}
        {(isSemillero === 0 || isSemillero === 2) && <Cultura />}
        {(isSemillero === 0 || isSemillero === 3) && <Deporte />}
        {(isSemillero === 0 || isSemillero === 4) && <Emprendimiento />}
        {(isSemillero === 0 || isSemillero === 5) && (
          // <Paz y DDHH/>
          <div></div>
        )}
        {(isSemillero === 0 || isSemillero === 6) && (
          <>
            <AdultoMayor />
            <Discapacidad />
            <Etnias />
            <Juventud />
            <Mujeres />
            <Interreligioso />
          </>
        )}
        {(isSemillero === 0 || isSemillero === 7) && <Psicojuridico />}
      </section>
    </main>
  );
};

export default Semilla;
