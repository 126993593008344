import Blog from "../../components/Blog";
import WhatsappButton from "../../components/whatsappbutton";
import Helmets from "../../widgets/Helmet";
import style from "./noticias.module.css";

const Noticias: React.FC = () => {
  return (
    <>
      <Helmets />
      <main className={style.MasterBoxNoticias}>
        <WhatsappButton />
        <div className={style.Banner}>
          <div className={style.overlay}>Noticias</div>
        </div>
        <Blog />
      </main>
    </>
  );
};

export default Noticias;
