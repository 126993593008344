import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const TwitterEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.TwitterEmbed,
  }))
);

const Semana17: React.FC = () => {
  const Notice1 = `
  En un hecho sin precedentes el Concejo de Cali hundió en segundo debate el proyecto de acuerdo No. 016 del 2024, la cual concedía facultades al alcalde de Santiago de Cali para modificar el Presupuesto General de Rentas, Recursos de Capital y Apropiaciones para gastos del Distrito de Santiago de Cali, para la Vigencia Fiscal comprendida entre el 1 de Enero y 31 de Diciembre del año 2024”.

  Con un total de 20 votos, incluyendo el del concejal del Partido Alianza Verde Rodrigo Salazar, durante la plenaria se votó negativo a este proyecto de acuerdo en donde se concedía facultades especiales al alcalde Alejandro Eder para modificar el presupuesto distrital de Santiago de Cali.

  Este proyecto se ha visto envuelto en varias problemáticas como el fallo del Tribunal Administrativo del Valle que tumbó 13 decretos por violar la constitución, el debidoproceso y por usurpar las funciones de la corporación, una actuación temeraria que rayaba en lo penal y disciplinario. 
  
  El pasado 6 de agosto de 2024 se puso en consideración de la Comisión de Presupuesto la proposición con la que termina la ponencia en primer debate del proyecto de Acuerdo No. 016 de 2024, la cual fue aprobada con seis votos a favor y uno en contra.

  “Votar negativo el proyecto de acuerdo #016 fue un acto de responsabilidad del concejo con Cali, hoy la victoria fue de la democracia, la institucionalidad y la corporación, dejando de lado intereses y poniendo a la constitución y la ley en primera fila”, manifestó el cabildante durante la plenaria extraordinaria.
  
  Al mismo tiempo que se dio a conocer a la opinión pública el bajo nivel de percepción positiva que tiene la ciudad sobre alcalde Alejandro Eder, el concejal verde Rodrigo Salazar instó a el mencionado y a su gabinete cambiar la forma en como están administrando la ciudad. 
  
  “Hacemos un llamado al alcalde y a su gobierno para que hagan una revisión y una autocrítica en como ser más respetuosos de la institucionalidad, que sea un llamado a corregir su rumbo porque hoy y a 8 meses de su gobierno aun no logra conectar con las necesidades de los caleños”, expresó Salazar. 
  
  En estudio sigue el proyecto de acuerdo #017 donde la administración deberá sustentar como invertirá y manejará un presupuesto de casi 2 billones de pesos en los siguientes 4 meses. `;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <TwitterEmbed
            url="https://x.com/rodrisalazarco/status/1829223543126798674?s=09"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Concejo de Cali le dice no al alcalde Eder para otorgarle facultades
          para modificar presupuesto distrital
        </h1>
        <h5 className={style.Fecha}>Agosto 29 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana17;
