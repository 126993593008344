import style from "./unete.module.css";

const Unete: React.FC = () => {
  const handleUnete = () => {
    window.location.replace("https://forms.gle/LZRzfBsCVa41svmB8");
  };

  return (
    <div className={style.UneteRS} onClick={handleUnete}>
      ÚNETE AQUÍ
    </div>
  );
};

export default Unete;
