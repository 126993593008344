import style from "./semilleros.module.css";
import Semilla from "../../components/Semilla";
import Helmets from "../../widgets/Helmet";
import WhatsappButton from "../../components/whatsappbutton";

const Semilleros: React.FC = () => {
  return (
    <>
      <Helmets />
      <main className={style.Semilleros}>
        <WhatsappButton />
        <Semilla />
      </main>
    </>
  );
};

export default Semilleros;
