import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana14: React.FC = () => {
  const Notice1 = `Con el fin de destacar la ardua labor de la Fundación Único y el Centro Comercial Único el honorable Concejo de Cali en cabeza del concejal Rodrigo Salazar, realizará una exaltación de honor por los programas de responsabilidad social corporativa desarrollados por la mencionada organización.

 Desde el año 2014 la Fundación Único se ha dedicado a generar acciones de valor y de bienestar para los residentes de las comunas 5,6 y 7 tales como programa de Jornadas Visuales y Auditivas Especializadas; Jornadas de Ortopedia Infantil; programa de Mejoramiento de Parques, Infraestructura Comunitaria y Zonas Verdes; Campañas de Donación de Sangre; Campañas de Adopción de Mascotas y Donación de Alimento; Campañas de Vacunación; Jornadas Deportivas, entre otras.
 
Así mismo, desde hace una década, se han dedicado a brindar una variada oferta de iniciativas que mejoran la vida de los niños, a través de acciones en materia de salud, educación, recreación, deporte, arte, cultura, fortalecimiento familiar, entre otras.

El cabildante proponente, Rodrigo Salazar, quien entregará la exaltación, comentó que se siente honrado de hacer el reconocimiento, ya que nació en el sector de Salomia, al norte de la ciudad, justo donde se encuentra el Centro Comercial.
“Es una fundación que aporta desde su que hacer por el desarrollo de la comunidad que rodea al único pero que también por la niñez y su bienestar, son un ejemplo de responsabilidad social empresarial, la cual, es un llamado a que las demas empresas de Cali sigan este ejemplo”.

Otro gran motivo por la cual se exaltará a la Fundación Único es por sus aportes a la niñez, brindando educación integral en los últimos siete años gracias al programa Atfer School, en donde se busca fortalecer las habilidades socioemocionales de niños y jóvenes de la mano de profesionales del área psicosocial en aras de fomentar una crianza positiva. “A los niños y niñas se les ofrece una oferta en actividades lúdicas, educativas, artísticas, culturales, deportivas y tecnológicas”, explicó Carlos Lara, Gerente de Responsabilidad Social Empresarial del Centro Comercial UNICO Outlet y Director Ejecutivo de la Fundación UNICO. 
La labor social que esta organización ha desarrollado ya ha sido reconocida internacionalmente en los premios CLICC, entregados en Brasil, galardonada en tres categorías: Responsabilidad social Empresarial con estatuilla de oro, Cultura también con oro y marketing con plata, además de ser elegido como uno de los mejores centros comerciales de Latinoamérica.

El concejal de Cali Rodrigo Salazar entregará al Centro Comercial Unico y a su Fundación Unico una Exaltación de Honor el próximo martes 30 de julio en el hemicilo del concejo, la entrada será libre.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C-Ai8K1gKQg/"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          El concejal Rodrigo Salazar exaltará a la Fundación Único del Centro
          Comercial único Outlet por destacada obra social en Cali
        </h1>
        <h5 className={style.Fecha}>Julio 29 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana14;
