import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import foto from "../../../assets/blog/SEPTIEMBRE-12-2024.jpg";

const Semana20: React.FC = () => {
  const Notice1 = `Te contamos que en los Talleres de emprendedores comunitarios y populares, más de 40 emprendimientos y sus familias se han podido beneficiar de nuestro acompañamiento y asesoría, logrando el registro efectivo a recientes convocatorias, como la Gran Convocatoria Nacional “Economía Popular para el Cambio”, del Ministerio de Prosperidad Social.

Una de las emprendedoras perteneciente a nuestro semillero, la Sra. Ayanny Ayala “La Chola”, quien rescata y desarrolla toda su actividad alrededor de la cocina ancestral y pacífica, expresó que “a través de estos talleres puedo ver el abanico de oportunidades y como mi emprendimiento se ha podido fortalecer y aplicar a diversas convocatorias”.

Igualmente, nos enorgullecemos de nuestras emprendedoras de la Comuna 15, Elsa Mosquera, Silvia Quiñones y Yaquelin Sandoval a quienes asesoramos y gestionamos su inscripción y aplicación efectiva a la Convocatoria del programa “Fortalecimiento técnico y productivo” de la Secretaría de Desarrollo Económico. 

“Estas capacitaciones son muy enriquecedoras, pues hemos aprendido cosas nuevas y obtenido muchos beneficios e insumos porque asistiremos sin falta a todas las capacitaciones”, señaló Yaquelin Sandoval, beneficiaria.

Si eres emprendedor o conoces a alguien con emprendimientos nos gustaría que te vincularas a nuestros futuros talleres que son completamente gratuitos.

Es importante que todos los emprendedores o personas que tengan una iniciativa se vinculen a través del link https://forms.gle/7h845FdyrGAyTiDP8, y así poder beneficiarse de las capacitaciones y asesoría que esta curul brinda constantemente, y participen de las convocatorias futuras.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Conoce nuestros talleres para emprendedores populares
        </h1>
        <h5 className={style.Fecha}>Septiembre 12 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana20;
