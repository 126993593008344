import Helmet from 'react-helmet';

const Helmets: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Rodrigo Salazar</title>
        <meta
          name="description"
          content="Sitio oficial de Rodrigo Salazar, concejal de Cali. Descubre sus propuestas, noticias y eventos."
        />
        <meta
          name="keywords"
          content="Rodrigo Salazar, concejal, Cali, propuestas, noticias"
        />
        <link
          rel="canonical"
          href="https://www.rodrigosalazarconcejalcali.com"
        />
      </Helmet>
    </>
  );
};

export default Helmets;
