import Navbar from "../Navbar";
import Footer from "../Footer";
import Style from './template.module.css';

interface Props {
    children: React.ReactNode;
  }

const Template: React.FC<Props> = ({children}) => {
  return (
    <main>
      <Navbar />         
      <div className={Style.BodyContent}>
        {children}
      </div>
      <Footer />
    </main>
  );
};

export default Template;