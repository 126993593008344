import style from "../blog.module.css";
import foto from "../../../assets/blog/Abril-15-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana8: React.FC = () => {
  const Notice1 = `El entamboramiento de canales de aguas lluvias, la construcción de más infraestructura ambiental en la ciudad y la implementación de acciones dirigidas a la descontaminación de los ríos, son temas claves que deben incluirse en el Plan de Desarrollo Distrital 2024-2027, el cual debe radicar la Alcaldía de Santiago de Cali ante el Concejo Distrital el próximo 30 de abril, para su discusión y aprobación durante mayo.
  Asimismo, se deben consolidar los proyectos de ‘Ecobarrios’, ‘Red Distrital de Huertas Urbanas’ y ‘Red Comunitaria de Bosques Urbanos’, que actualmente no cuentan con indicadores propios que comprometan a la Administración Distrital con el fortalecimiento de las capacidades técnicas, operativas y organizativas que estos procesos socio ambientales necesitan.
  “Desde nuestro trabajo participativo en el Concejo, hemos venido trabajando en mesas técnicas con diferentes comunidades y líderes ambientales de la ciudad para identificar oportunidades de mejora en la propuesta de Plan de Desarrollo Distrital, cuya primera versión fue radica ante el Cabildo el pasado 29 de febrero. En este sentido, queremos solicitarle al gobierno del alcalde Alejandro Eder la inclusión de diferentes indicadores relacionados con el agua, aire, vida urbana, alimentación y tierra en el documento que será la hoja de ruta de nuestra ciudad para los próximos 4 años”, expuso Rodrigo Salazar, concejal de Cali por el Partido Alianza Verde. 

Indicadores por la protección del agua
Cali tiene 10 ríos (7 urbanos y 3 rurales) y 61 humedales (más de 20 en espacios públicos), que constituyen la mayor fuente de biodiversidad en la ciudad. Estos cuerpos de agua dan forma al territorio y definen corredores biológicos de fauna y flora que, además, mitigan la temperatura del aire.
“Por ello, es necesario avanzar en la definición de los planes de manejo ambiental de los humedales, principalmente en la zona de expansión urbana y en los corregimientos de Navarro y El Hormiguero. Asimismo, es necesario garantizar programas urgentes de descontaminación de los ríos de la ciudad, que están siendo impactados por la minería ilegal en los Farallones de Cali, vertimientos ilegales de aguas residuales y malas prácticas por parte de habitantes de calle”, señaló Rodrigo Salazar. 
Indicadores por la protección del aire
Debido a la dinámica de vientos y a su ubicación geográfica, Cali es una de las ciudades industriales de Colombia con mejor calidad del aire. “Es importante entonces mantener un monitoreo permanente, principalmente en las zonas industriales cercanas a zonas residenciales, para garantizar las condiciones de calidad del aire a la ciudadanía. El monitoreo del aire también permite evaluar el impacto del tráfico motorizado y promover campañas como el día sin carro”, subrayó.
Indicadores por la reconciliación de la modernidad con el ambiente
La implementación de estrategias que afiancen modelos pedagógicos de apropiación de buenas prácticas ambientales y permitan el desarrollo de comunidades comprometidas con la preservación del patrimonio ambiental regional, es otro tema prioritario en la ciudad que debe ser incorporado en el Plan de Desarrollo Distrital. 
“Entre el 21 de octubre y 1 de noviembre de 2024, Cali será sede la Convención sobre Biodiversidad de Naciones Unidas, COP16. Este evento requiere que haya monitores permanentes en calidad de enlaces, que puedan evaluar oportunidades, alistar comunidades, socializar la información y articular esfuerzos de la Administración con la ciudadanía y las organizaciones en cada comuna”, manifestó el cabildante. 
De igual manera, se debe fortalecer el proyecto de ‘Ecobarrios’, que ha mostrado ser un referente nacional e internacional en términos de buenas prácticas comunitarias y la construcción de economías circulares con alto valor agregado. 
“La meta sería que cada comuna pueda avanzar en el cuatrienio en, al menos, un piloto de ecobarrio, que resuelva de manera integral los determinantes ambientales y sirva de ejemplo a otros barrios. Es importante que estos esfuerzos pedagógicos incluyan elementos de apropiación cultural que enamoren a la ciudadanía de su riqueza ambiental y le brinden conocimientos sobre su ciudad”, destacó. 
Indicadores por la seguridad alimentaria
Cali se ha convertido en una ciudad pionera dentro de un movimiento global de huerteros urbanos, que han visto la oportunidad de realizar pedagogía ambiental y de buenas prácticas agrícolas y agro ecológicas, al tiempo que generan espacios de restauración de ecosistemas. 
Actualmente, la capital del Valle cuenta con más de 200 huertas activas que permiten el intercambio de saberes y la construcción de comunidades activas que convergen en actividades sanas, educativas y productivas. Estos espacios han ganado una importancia significativa en la formación de niños, niñas y adolescentes, y en la mitigación de problemáticas sociales en los barrios, reduciendo indicadores de violencia.
“La ‘Red Distrital de Huertas Urbanas’ es una inmensa oportunidad para territorializar un modelo pedagógico práctico que conecte a la ciudadanía digital con saberes que cada día resultan más lejanos, y le enseñe sobre la producción de alimentos, el manejo de residuos orgánicos, la mitigación de plagas, plantas medicinales y comestibles, técnicas de cocina, entre otros”, dijo. 

Por otro lado, los productores rurales, que tienen una visión productiva y empresarial desde una perspectiva agrícola, también requieren verse reflejados en el Plan de Desarrollo Distrital, por lo que facilitar las condiciones de acceso a mercados para productores rurales es una necesidad sentida por los caleños que viven en la zona rural. “Toda mejora en el acceso a mercados de un productor rural representa una mejora sustancial en la calidad de vida de las familias campesinas”, agregó Salazar. 
Indicadores por las infraestructuras ambientales
Cali tiene un profundo déficit de espacio público efectivo por habitante. Esta situación se hace más crítica en las comunas de la ciudad en las que habita la población más vulnerable, lo que constituye una doble condición de vulnerabilidad, que detona dinámicas de disputa y confrontación por el espacio público.
Lo mismo ocurre con la distribución del arbolado en la ciudad, donde las zonas más vulnerables tienen menores densidades arbóreas. Es una responsabilidad del Estado garantizar condiciones de desarrollo humano integral y un hábitat saludable.
“En este sentido, una gran oportunidad se encuentra en el entamboramiento de los canales de aguas lluvias, que permitirá adecuar espacios públicos multifuncionales de alto impacto social, como el Bulevar de Oriente, antiguo canal Cauquita. El mejoramiento de canales y separadores viales brinda además mejores condiciones de paisajismo en los barrios, aporta a la proliferación de polinizadores, y reduce los vectores de salud y riesgo asociados a la contaminación por residuos sólidos”, enfatizó. 
Por otro lado, una experiencia muy exitosa y persistente en el desarrollo de infraestructuras ambientales para la ciudad es la ‘Red Comunitaria de Bosques Urbanos’, que se han convertido en pulmones para la ciudad que permiten mitigar las olas de calor, reducir los niveles de contaminación del aire y el diseño de productos turísticos asociados al avistamiento de aves, entre otros beneficios. 
“Se requiere fortalecer sus capacidades técnicas y operativas para maximizar el impacto territorial y avanzar hacia una estrategia más ambiciosa de reforestación urbana, en la que el Dagma pueda apalancar la operación del vivero distrital y descentralizar la oferta de material vegetal disponible”, comentó. 
Finalmente, en términos de infraestructuras ambientales, es importante que, al menos se, pueda avanzar en el diseño y construcción de un nuevo ecoparque “que evoque la reconciliación de la que habla el Plan de Desarrollo y que rinda un homenaje a nuestra ciudadanía”. 
“Hoy el Dagma cuenta con la experiencia para adelantar proyectos de importancia, y el oriente de la ciudad requiere con urgencia un gran parque que aporte a mejorar los indicadores de espacio público a la población más vulnerable”, concluyó Rodrigo Salazar. 

  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Curul El Cambio es con Vos pide priorizar temas en el Plan de
          Desarrollo Distrital s
        </h1>
        <h5 className={style.Fecha}>Abril 15 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana8;
