import style from "./links.module.css";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Animated as Animatedx } from "react-animated-css";
import Helmets from "../../widgets/Helmet";
import foto from "../../assets/links/rslinkfoto.png"

const LinksUtils: React.FC = () => {
  const [isVisibleLinks, setIsVisibleFrase] = useState(true);
  const handleIG = () => {
    window.location.href = 'https://www.instagram.com/rodrisalazarco/';
  };
  const handleFB = () => {
    window.location.href = 'https://www.facebook.com/rodrisalazarco/';
  };
  const handleTW = () => {
    window.location.href = 'https://x.com/rodrisalazarco';
  };
  const handleTK = () => {
    window.location.href = 'https://www.tiktok.com/@rodrisalazarco';
  };
  const handleWEB = () => {
    window.location.href = 'https://rodrigosalazarconcejalcali.com';
  };  
  return (
    <>
    <Helmets />
    <main className={style.MasterLinks}>
      <VisibilitySensor
        partialVisibility
        onChange={(isVisibleLinks: boolean) =>
          setIsVisibleFrase(isVisibleLinks)
        }
      >
        <Animatedx
          {...{
            animationIn: "zoomIn",
            animationOut: "fadeOut",
            animationInDuration: 3000,
            // animationOutDuration: 10000,
            isVisible: isVisibleLinks,
          }}
        >
          <div className={style.RSimg}>
          <img className={style.FotoRSImg} src={foto} alt="FotoRodrigo" />
          </div>
        </Animatedx>
      </VisibilitySensor>
      <p className={style.RSTittlex}>Rodrigo Salazar ✅</p>
      <p className={style.RSsubtittlex}>Concejal de Cali. Partido Alianza Verde 🌻 #ElCambioEsConVos </p>
      <div className={style.RSbuttonsx} onClick={handleWEB}>PAGINA WEB</div>
      <div className={style.RSbuttonsx} onClick={handleFB}>FACEBOOK</div>
      <div className={style.RSbuttonsx} onClick={handleIG}>INSTAGRAM</div>
      <div className={style.RSbuttonsx} onClick={handleTW}>TWITTER</div>
      <div className={style.RSbuttonsx} onClick={handleTK}>TIKTOK</div>
    </main>
    </>
    
  );
};

export default LinksUtils;
