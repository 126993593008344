import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const TwitterEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.TwitterEmbed,
  }))
);

const Semana18: React.FC = () => {
  const Notice1 = `Tras la propuesta del alcalde Alejandro Eder junto a la gobernadora Dilian Francisca Toro y otros mandatarios de declarar la caña de azúcar como paisaje cultural ante la UNESCO, varias organizaciones ambientales y sociales han alzado su voz de rechazo.

Por su parte el concejal del Partido Alianza Verde Rodrigo Salazar dejó constancia durante las recientes plenarias extraordinarias su postura frente a este planteamiento.
“Declarar paisaje cultural de la caña de azúcar es un error, pues consideramos que los efectos negativos, tanto ambientales como sociales, derivados del monocultivo de caña de azúcar, no permiten construir una narrativa positiva y de apropiación cultural y turística”, manifestó Salazar.
El cabildante aclaró que si es necesario que se articulen esfuerzos interinstitucionales para fortalecer y complementar las capacidades que tienen cada uno de nuestros territorios, como lo fue la iniciativa que ha tenido el Municipio de Candelaria para convocar a una cumbre de mandatarios en Juanchito, en la que converjan distintos equipos de trabajo y propicien una agenda conjunta que apunte al turismo regional, la preservación del medio ambiente, la reactivación de corredores económicos y productivos, y otros grandes proyectos de infraestructura que dinamicen la competitividad regional como la navegabilidad del Río Cauca o el tren de cercanías.
Así mismo, Salazar destacó el pasado colonial y de esclavitud que ha tenido en nuestra región, principalmente asociado a las explotaciones agrícolas de caña de azúcar y cómo estos fenómenos ayudaron a moldear la desigualdad estructural, el desplazamiento y la concentración de la tierra, que han llevado a luchas campesinas, indígenas y afro que han planteado resistencia al modelo extractivista y feudal, que una docena de ingenios azucareros (representados en solo 15 familias) han instaurado y por medio del cual hoy controlan más de 230 mil hectáreas de suelo cultivable, solo en el Valle del cauca, mientras que los cultivos de café se desarrollan a través de parcelas de familias campesinas.
Otro gran inconveniente de esta propuesta es dentro del ámbito ambiental pues la practica del cultivo de caña produce daños como degradación de suelo, contaminación del agua, pérdida de biodiversidad, deforestación e impacto en los ecosistemas y problemas de salud.
Se trata, entonces en palabra del profesor Germán Ayala de la Universidad Nacional, de un proceso de sometimiento y domesticación de la naturaleza con implicaciones sociales, culturales, económicas y políticas, que extienden en el tiempo el empobrecimiento estético que produce un monocultivo como el de la caña de azúcar, al que insisto, no podemos otorgar un reconocimiento o estatus de protección cultural.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <TwitterEmbed
            url="https://twitter.com/rodrisalazarco/status/1828870858141491254"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          ¿La ceniza de la caña será “un paisaje cultural” para el Valle?
        </h1>
        <h5 className={style.Fecha}>Agosto 31 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana18;
