import style from "../blog.module.css";
import foto from "../../../assets/blog/Febrero-23-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana3: React.FC = () => {
  const Notice1 = `Tras el anuncio del Gobierno Nacional por parte del presidente Gustavo Petro y el Ministerio del Medio Ambiente encabezado por Susana Muhamad, de designar a la ciudad de Cali como sede para la realización de la Conferencia sobre Biodiversidad de Naciones Unidas - COP16, Rodrigo Salazar concejal del Partido Alianza Verde propuso en plenaria ordinaria la formación de una comisión especial desde el Concejo de Cali. 
  Lo anterior, con el objetivo de hacer seguimiento a la definición y desarrollo del Plan de Acción que se establezca para la realización de un evento del tamaño e importancia como lo es la Convención sobre Biodiversidad de Naciones Unidas COP16 que tendrá lugar en la ciudad desde 21 de octubre al 1 de noviembre de 2024.
  Salazar considera que esta solicitud es pertinente ya que para la realización de este evento de gran envergadura implica una organización logística que implica una utilización de recursos públicos que deben tener una veeduría, planes de seguridad que garanticen la integridad de todas las delegaciones y visitantes, una campaña comunicativa y de medios dirigida a toda la ciudadanía caleña y del suroccidente colombiano donde se socialice el evento y el impacto positivo y de desarrollo que traerá a Cali, al igual que la creación de espacios para de participación ciudadana que faciliten el acceso a canales de información. 
  Otras razones de peso que avalan la instauración de la comisión según el concejal Salazar Sarmiento es que se necesita que la ciudad de Cali tenga un proceso de enlucimiento completo coayudado con actores sociales y políticos, en aras de la recuperación de espacios públicos y reconciliación social, así mismo, la comisión sería garante para que la Administración Distrital agilice las obras de los “Cinco Parques para la Vida”, que serán vitales dentro del desarrollo y actividades a desarrollarse en la cumbre, gestionar otros proyectos para la región y medición del impacto socioeconómico.
  La Convención de Naciones Unidas sobre Biodiversidad COP16 es una gran oportunidad que le permitirá a la sociedad caleña y a instituciones como el Concejo de Cali, un espacio para reflexionar sobre mecanismos de protección ambiental y mitigación frente al cambio climático, además de cómo hacerles frente a las amenazas a la basta diversidad del Distrito de Santiago de Cali.
  Esta cumbre también representa una visión de reconciliación para nuestra sociedad en torno a diálogos que le apuntan a la protección de nuestros entornos.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          El concejal del Partido Alianza Verde Rodrigo Salazar propone creación
          de una comisión especial para el COP16
        </h1>
        <h5 className={style.Fecha}>Febrero 23 del 2024</h5>
        <ExpandableText text={Notice1} limit={300} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana3;
