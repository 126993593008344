import style from "../blog.module.css";
import foto from "../../../assets/blog/Marzo-20-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana7: React.FC = () => {
  const Notice1 = `Ante la crítica situación que atraviesan los canales de aguas lluvias de Cali por el impacto de residuos sólidos, Rodrigo Salazar, concejal por el Partido Alianza Verde, propuso entamborar estos sistemas de drenaje y convertirlos en espacios para el disfrute ciudadano como zonas verdes, bulevares y ciclorrutas. 
  Cali tiene una red de 70 kilómetros de canales de aguas lluvias, de los cuales cerca del 20 % está entamborada. No obstante, la parte que no cuenta con este tipo de infraestructura, permanentemente se ve aquejada por el arrojo clandestino de todo tipo de residuos, como colchones, mobiliario, animales muertos y escombros. 
  Además, Emcali retira diariamente 400 toneladas de basura de estos espacios. El canal Oriental (en Petecuy), el canal que pasa por la galería Santa Elena y el canal de la Autopista Suroriental, son algunos de los más afectados.  
  “Los canales de aguas lluvias de Cali están altamente contaminados y representan un factor de riesgo ambiental y sanitario muy elevado.  Ante este panorama, les estamos proponiendo al gobierno del alcalde Alejandro Eder que incluya la tamborización de los canales de aguas lluvias en el Plan de Desarrollo Distrital, que podría costar unos $200.000 millones. Esta inversión  se traduciría en más espacio público para los habitantes de la ciudad con la construcción de jardínes, parques y ciclorrutas”, resaltó Salazar. 
  Una experiencia positiva en este sentido fue la transformación del antiguo ‘Canal Cauquita’ en el ‘Bulevar del Oriente’, un parque lineal que impactó de manera positiva el distrito de Aguablanca y que hoy es epicentro de deporte, arte y cultura. 
  Asimismo, se destaca la peatonalización del canal de aguas lluvias de la Autopista Suroriental, entre carreras 37 y 31, que inició en septiembre de 2023 con el objetivo principal de corregir fallas y el deterioro estructural causado, principalmente, por las raíces de árboles de gran tamaño sembrados en zonas blandas.
  “Sería muy importante poder continuar el entamboramiento de toda la red de este canal, que traería como beneficios colaterales el embellecimiento paisajístico de los sectores que atraviesa y la reducción del arrojo clandestino de residuos en este espacio”, subrayó. 
  No obstante, agregó, esto solo será posible con la implementación de campañas contundentes de cultura ciudadana por parte de la Administración Distrital, enfocadas en promover la adecuada disposición de los residuos. 
  “Y sobre todo, con acciones de inspección, vigilancia y control para sancionar a todas aquellas personas que se empeñen en seguir arrojando residuos a los canales de aguas lluvias y al espacio público en general”, enfatizó. 
  Los canales de aguas lluvias hacen parte del sistema de drenaje de la ciudad. Mantener estos espacios libres de residuos evita el represamiento de las aguas lluvias en algunos sectores y la inundación de vías. 
  “Hoy, tenemos un desafío enorme por delante: somos sede de la COP16, la cumbre de biodiversidad más importante del planeta, que es una gran oportunidad para recuperar el civismo y comprometernos entre Estado y sociedad a cuidar el medio ambiente”, concluyó Salazar.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Entamborar todos los canales de aguas lluvias y residuales un proyecto
          para generar nuevos espacios públicos
        </h1>
        <h5 className={style.Fecha}>Marzo 20 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana7;
