import style from "./recursoconcejal.module.css";

const RecursoConcejal: React.FC = () => {
  const handleUnete = () => {
    window.location.replace("https://drive.google.com/drive/folders/1V6XF6BAs8cMGBtNZ1TpSC_A8OWOwVjU_?usp=sharing");
  };
  return (
    <main className={style.BannerFondoRecursoConcejal}>
      <div className={style.BoxSizeRecursoConcejal}>
        <h1 className={style.fraseRecursoConcejal}>
          Queridos Concejales pueden acceder de forma mas rapida a los
          documentos del Conversatorio - AMSO.
        </h1>
        <div
          className={style.PublicButtomRecursoConcejal}
          onClick={handleUnete}
        >
          👉AQUí👈
        </div>
      </div>
    </main>
  );
};

export default RecursoConcejal;
