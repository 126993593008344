import style from "../blog.module.css";
import foto from "../../../assets/blog/Febrero-27-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana4: React.FC = () => {
  const Notice1 = `Tras la llegada inminente del Fenómeno del Niño y las amenazas que este representa para la fauna y flora del Distrito de Santiago de Cali, el concejal del Partido Alianza Verde adelantó una proposición de debate de control político a Secretaría de Gestión del Riesgo de Emergencias y Desastres y al DAGMA.
  Esto con el fin de que se dé a conocer a la ciudadanía caleña cuales han sido y/o serán los mecanismos de prevención de estos organismos para hacerle frente a esta problemática, según el IDEAM el 23 de enero se había registrado las más altas temperaturas en varios municipios de Colombia batiendo record. 
  Entre las principales afectaciones que preocupa a la curul verde de Salazar son amenazas como actividades de alto impacto, asentamientos humanos de desarrollo incompleto (AHDI), minería ilegal y sobre todo incendios forestales que son generados en mayoría por acciones humanas negligentes y de manera premeditada.
  “Estamos atravesando un cambio drástico en las temperaturas en Cali, provocando incendios como el ocurrido en las últimas horas en el municipio de Jamundí, por eso citamos al DAGMA y Gestión del Riesgo en el Concejo de Cali para que nos expliquen cuáles son esos instrumentos y capacidad operativa que tienen en estos momentos para mitigar y prevenir los efectos del fenómeno del niño”, manifestó el cabildante. 
  Así mismo, otras denuncias planteadas a los organismo han sido sobre actividades de motocross que se ha ido desarrollando en los cerros occidentales, afectando a alrededor de 4 mil familias del sector rural y a la fauna y flora e incrementando los riesgos por incendios tras el vertimiento de combustibles.
  Como objetivo del debate desde la curul de Salazar se le planteó a los organismos citados y a la Alcaldía Distrital la necesidad de un proyecto de reforestación urbana que contemple una meta de un millón de árboles para afrontar el cambio climático y aporte en la restauración del bosque seco tropical uno de los ecosistemas más frágiles.

  RESPUESTAS DEL DAGMA Y RIESGOS  
  Por último, luego de que se anunciara por parte del Gobierno Nacional y el Ministerio del Medio Ambiente encabezado por Susana Muhamad, de designar a la ciudad de Cali como sede para la realización de la Conferencia sobre Biodiversidad de Naciones Unidas - COP16, se hace mucho más pertinente el fortalecimiento de medidas preventivas, sin embargo, los operativos de control en zonas protegidas dentro del área urbana de Cali deben seguir siendo permanentes y casos como el de la afectación a la cuenca media del río Lili no se repitan.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>En debate de control el concejal de Cali Rodrigo Salazar le puso el ojo al fenómeno del niño </h1>
        <h5 className={style.Fecha}>Febrero 27 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana4;
