import Buttondown from "../../components/Buttondown";
import style from "./knowme.module.css";
import Img1 from "../../assets/rodrigo.jpg";
import Img2 from "../../assets/bio3.png";
import Img3 from "../../assets/bio2.jpg";
import { useState } from "react";
import { Animated } from "react-animated-css";
import VisibilitySensor from "react-visibility-sensor";
import Helmets from "../../widgets/Helmet";
import Letters from "../../widgets/Letters";
import WhatsappButton from "../../components/whatsappbutton";

const Knowme: React.FC = () => {
const Parrafo1 = `Soy Rodrigo Salazar, nací en la bella ciudad de Cali y soy el sexto hijo de seis hermanos, criados por el amor de un padre caldense y una madre caucana.

Abogado de profesión y especializado en Derecho Constitucional y Administrativo. Cuento con más de 25 años de experiencia en la gestión pública y tengo una trayectoria de 30 años liderando procesos sociales, comunitarios y de defensa de los Derechos Humanos.

Padre de cuatro hijos y esposo de una bella mujer del Pacífico colombiano. Hombre de fe y sobreviviente de un cáncer linfático, del que fui sano por la grandeza y misericordia de Dios.`;

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  return (
    <>
      <Helmets />
      <main className={style.Master}>
        <WhatsappButton />
        {/* SECCION 1 */}
        <section className={style.Bio1}>
          <div className={style.BoxBio1}>
            <div className={style.BoxText}>
            <h1 className={style.lettersTitulo}>Explora mi identidad</h1>
              <Letters parrafo={Parrafo1} />
            </div>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible1: boolean) => setIsVisible1(isVisible1)}
            >
              <Animated
                {...{
                  animationIn: "pulse",
                  animationOut: "fadeOut",
                  animationInDuration: 1000,
                  isVisible: isVisible1,
                }}
              >
                <div className={style.BioImgBox1}>
                  <img
                    className={style.BioImg1}
                    alt="Rodrigo Salazar"
                    src={Img1}
                  />
                </div>
              </Animated>
            </VisibilitySensor>
          </div>
          <div className={style.Buttondwn}>
            <Buttondown />
          </div>
        </section>
        {/* SECCION 2 */}
        <section className={style.Bio2}>
          <div className={style.BoxBio2}>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible2: boolean) => setIsVisible2(isVisible2)}
            >
              <Animated
                {...{
                  animationIn: "slideInLeft",
                  animationOut: "fadeOut",
                  animationInDuration: 1000,
                  isVisible: isVisible2,
                }}
              >
                <div className={style.BioImgBox2}>
                  <img
                    className={style.BioImg2}
                    alt="Rodrigo Salazar"
                    src={Img2}
                  />
                </div>
              </Animated>
            </VisibilitySensor>
            <div className={style.BoxText2}>
              <h1 className={style.tittle2}>Trayectoria</h1>
              <div className={style.text2}>
                <p>
                  Creo que nuestra existencia está dada por un{" "}
                  <b style={{ fontWeight: 800, fontSize: "25px" }}>
                    propósito superior que en mi caso es el de servir
                  </b>
                  , misión que he realizado desde la gestión social, el trabajo
                  comunitario y la labor pública. En este sector, ha ocupado
                  cargos jurídicos y directivos en entidades como: Hospital
                  Universitario del Valle, Red de Salud de Ladera, Empresa de
                  Recursos Tecnológicos, Metrocali, Unidad de Restitución de
                  Tierras, Dagma y el Tribunal de Garantías Electorales.
                  Asimismo, fui consultor de la Unidad Coordinadora Municipal
                  del empréstito para la convivencia y seguridad ciudadana de
                  Cali del Banco Interamericano de Desarrollo, secretario de
                  Desarrollo Territorial y Participación Ciudadana, y asesor de
                  la Alcaldía de Santiago de Cali en temas de participación
                  ciudadana.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* SECCION 3 */}
        <section className={style.Bio3}>
          <div className={style.BoxBio3}>
            <div className={style.BoxText3}>
              <h1 className={style.tittle3}>
                Concejal electo de Cali <br />
                2024-2027
              </h1>
              <div className={style.text3}>
                <p>
                  El 29 de octubre del 2023 fui elegido concejal de Cali por el
                  Partido Alianza Verde, curul a la que llegué liderando el
                  colectivo de ‘El cambio es con vos’, que reconoce en cada ser
                  humano el potencial transformador para una sociedad más justa,
                  democrática y responsable con la naturaleza
                  <br /> <br />
                  Nuestro movimiento cuenta con el respaldo de diversas
                  organizaciones sociales, que confían en nuestra labor de
                  abogar por sus problemáticas, visibilizar sus proyectos
                  comunitarios y darles voz en un espacio de participación
                  política tan importante, como lo es el Concejo. Lo anterior,
                  en aras de hacer de Cali un Distrito Especial, diverso y
                  reconciliado en propósitos comunes.
                  <br /> <br />
                  Mi curul en el Concejo de Cali, la hemos direccionado en las
                  que consideramos{" "}
                  <b
                    style={{
                      fontWeight: 800,
                      fontSize: "25px",
                      color: "#0C672B",
                    }}
                  >
                    cuatro ejes transversales{" "}
                  </b>{" "}
                  que son fundamentales para la ciudadanía caleña. Estos son{" "}
                  <b
                    style={{
                      fontWeight: 800,
                      fontSize: "25px",
                      color: "#179D20",
                    }}
                  >
                    Medio Ambiente
                  </b>
                  ,{" "}
                  <b
                    style={{
                      fontWeight: 800,
                      fontSize: "25px",
                      color: "#6EA764",
                    }}
                  >
                    Paz y Derechos Humanos
                  </b>
                  ,{" "}
                  <b
                    style={{
                      fontWeight: 800,
                      fontSize: "25px",
                      color: "#76BA1C",
                    }}
                  >
                    Poblaciones
                  </b>{" "}
                  y{" "}
                  <b
                    style={{
                      fontWeight: 800,
                      fontSize: "25px",
                      color: "#988100",
                    }}
                  >
                    Desarrollo Económico
                  </b>{" "}
                  (economía popular). A través del ejercicio del control
                  político y proyectos de acuerdo, estoy enfocado en impulsar
                  iniciativas que contribuyan al bien común y al desarrollo
                  incluyente y sostenible de Cali.
                  <br /> <br />
                  <b
                    style={{
                      fontFamily: "wellcomehome",
                      fontWeight: 800,
                      fontSize: "32px",
                      color: "#0C672B",
                      textShadow: "0px 2px 1px #000",
                    }}
                  >
                    ¡El cambio es{" "}
                  </b>
                  <b
                    style={{
                      fontFamily: "wellcomehome",
                      fontWeight: 800,
                      fontSize: "32px",
                      color: "#FFDF3F",
                      textShadow: "0px 2px 4px #000",
                    }}
                  >
                    con vos!
                  </b>
                </p>
              </div>
            </div>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible3: boolean) => setIsVisible3(isVisible3)}
            >
              <Animated
                {...{
                  animationIn: "slideInRight",
                  animationOut: "fadeOut",
                  animationInDuration: 1000,
                  isVisible: isVisible3,
                }}
              >
                <div className={style.BioImgBox3}>
                  <img
                    className={style.BioImg3}
                    alt="Rodrigo Salazar"
                    src={Img3}
                  />
                </div>
              </Animated>
            </VisibilitySensor>
          </div>
        </section>
      </main>
    </>
  );
};

export default Knowme;
