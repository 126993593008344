import foto from "../../../assets/semilleros/etnias/afro.png";
import foto1 from "../../../assets/semilleros/etnias/etn1.jpg";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Etnias: React.FC = () => {
  const Notice1 = `La marimba, conocida como el "Piano de la Selva", es un instrumento de percusión emblemático compuesto por láminas de madera dispuestas en orden ascendente de tamaño, cada una con su tono característico al ser golpeada con mazos. ¿Cuánto sabes sobre la marimba de chonta? Este instrumento ancestral y autóctono es uno de los tesoros más preciados de Colombia, especialmente en la región del Pacífico, donde ha florecido en la música local y ha sido reconocido como Patrimonio Inmaterial de la Humanidad en 2010. Se dice que su afinación original imitaba el canto de las aves y el murmullo del agua, buscando capturar la esencia misma de la naturaleza.

  La historia de la marimba de chonta tiene raíces profundas en África, específicamente en el Congo, de donde los esclavos la llevaron consigo y la adaptaron para emular el bafalón, un instrumento que quedó atrás. Su presencia se extendió desde la costa norte de Ecuador hasta lugares como Barbacoas y Buenaventura. La madera de Palma de Chonta es fundamental en su construcción, junto con 18 o 24 placas, resonadores de guadua y baquetas con bolas de caucho natural en las puntas. Además, su afinación tradicional se realiza en armonía con la voz de las cantaoras, creando una sinergia única entre el sonido y el canto.
  
  La marimba de chonta da vida a ritmos como el bunde, la juga y el currulao berejú, dotándolos de una identidad inigualable y transmitiendo el alma misma del pueblo afrodescendiente que la ha mantenido viva a lo largo de generaciones. Su resonancia no solo lleva consigo la historia de su origen, sino también el espíritu de una comunidad que encuentra en la música una expresión de su identidad y un lazo con sus raíces ancestrales.`;
  const Notice2 = `El Encuentro Cultural del Pacífico, celebrado en el Parque el Ingenio al sur de Cali, fue mucho más que una simple integración de culturas: fue un vívido homenaje a la riqueza ancestral y la diversidad del Pacífico colombiano, fusionándose armoniosamente con la vibrante comunidad caleña. En este evento, nuestro candidato al Concejo de Cali, Rodrigo Salazar, se destacó al compartir con alegría y admiración los saberes ancestrales, acompañado por los hipnóticos ritmos de la marimba, el conuno y el guasá.

  Los asistentes no solo reconocieron al candidato, sino que lo aclamaron como "la mejor opción" para representar sus intereses en el Concejo Municipal. A través de la confianza y la expresión de su cultura, cada participante contribuyó a un ambiente de celebración y aprecio por las tradiciones del Pacífico. Desde la exquisita gastronomía hasta la moda y la música, cada aspecto del evento reflejaba la autenticidad y la originalidad de la región costera.
  
  Los productos artesanales, los peinados tradicionales, los cosméticos naturales y los diseños de moda cautivaron a todos los presentes, quienes no solo disfrutaron de la fiesta cultural, sino que también se sintieron conectados con la esencia misma del Pacífico, llevando consigo un pedazo de su litoral en el corazón y expresando su cariño y aprecio por esta herencia cultural.
  
  El evento fue elogiado por su organización impecable, su presentación estética y su compromiso con la sostenibilidad ambiental. La Comisión Afro de la candidatura del Dr. Rodrigo Salazar fue la mente maestra detrás de esta celebración inolvidable, demostrando que el cambio y la promoción de la diversidad cultural son posibles cuando nos unimos en un espíritu de armonía y colaboración. ¡Porque el cambio es contigo, vota #7, Partido Alianza Verde!`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Septiembre 8 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            La historia de los instrumentos musicales del Litoral
          </h1>
          <h5 className={style.Fecha}>Septiembre 8 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
      {/*Noticia Agosto 30 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>Encuentro cultural del pacifico</h1>
          <h5 className={style.Fecha}>Agosto 30 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Etnias;
