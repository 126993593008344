import style from "../blog.module.css";
import foto from "../../../assets/blog/Febrero-03-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana2: React.FC = () => {
  const Notice1 = `- El Jarillón del Río Cauca es una de las zonas más afectadas por el
  arrojo clandestino de escombros.
  - Una de las escombreras, ubicada en el asentamiento El Valladito, es
  3 veces más grande que la Estación de Transferencia de la Carrera 50
  con Autopista Simón Bolívar.
  - Bandas criminales controlan escombreras ilegales cobrando tarifas de
  ingreso y loteando terrenos para la construcción de invasiones.
  Cerca del 60 % de los Residuos de Construcción y Demolición (RCD) de
  origen residencial que se generan en Santiago de Cali son arrojados de
  manera clandestina en el espacio público o terminan en escombreras
  ilegales.

  Así lo dio a conocer el concejal del Partido Verde, Rodrigo Salazar,
  quién hizo un llamado a la Administración Distrital a tomar acciones
  de control para clausurar estos lugares y evitar que sigan impactando
  el medio ambiente y perjudicando la salud de los habitantes de la
  ciudad.
  
  En Cali, el único sitio avalado para la disposición transitoria
  de los RCD (o escombros) es la estación de Transferencia de la Carrera
  50 con Autopista Simón Bolívar, que recepciona diariamente cerca de
  350 toneladas de estos materiales. Desde allí, los residuos son
  trasladados hasta sitios de disposición final ubicados en municipios
  vecinos.
  Sin embargo, de acuerdo a información de la Asociación Mutual
  Eco Ambiental de Cali -Asomutual- (que agremia a los chaneros), existe
  un subregistro de 600 toneladas de escombros que, en vez de ser
  llevados hasta la estación de transferencia, están terminando en el
  espacio público o en escombreras ilegales.

  Las escombreras ilegales más grandes que se tiene identificadas
  están ubicadas en los siguientes lugares: 

  1- Atrás de la PTAR de Cañaveralejo (Comuna 5).
  2- Barrio Petecuy (Comuna 5).
  3- Barrio Alfonso López (Comuna7).
  4- Laguna del Pondaje (Comuna 13).
  5- Asentamiento El Valladito (Comuna15).
  6- Cerca al Hospital Isaías Duarte Cancino (Comuna 15).
  7- Asentamiento Brisas de Comuneros (Comuna 15).
  8- Invasión Haití (Comuna 15).
  9- Barrio Bochalema (Comuna 17).
  10- Parte alta de Siloé (Comuna 20).
  11- Al lado del Antiguo control de la Crema y Rojo Control crema y
  rojo (Comuna 21).
  12- Barrio Desepaz (comuna 21).
  13- Asentamiento Samanes del Cauca (Comuna 21).
  14- Corregimiento de Navarro.

  Adicionalmente, existen escombreras en la zona metropolitana de Cali
  como el asentamiento El Silencio (en el corregimiento de Juanchito, en
  el municipio de Candelaria y en los corregimientos La Dolores y
  Caucaseco) en el municipio de Palmira.

  “La situación de las escombreras ilegales es cada vez más crítica. Por
  ejemplo, en el asentamiento El Valladito, hay una escombrera que es 3
  veces más grande que la estación de Transferencia de la Carrera 50, y
  donde pueden haber depositadas unos 30.000 toneladas de residuos,
  teniendo en cuenta que a la estación le caben cerca de 11.000
  toneladas de RCD”, detalló Salazar.

  El cabildante añadió que también “es alarmante la escombrera ubicada
  en el Jarillón del río Cauca (en el barrio Petecuy) que crece cada día
  más en forma de montaña y que está contaminando el afluente, adonde
  caen sin contemplación todo tipo de residuos, el cual surte de agua a
  casi el 90 % de la población de Cali”.`;

  const Notice2 = `Rodrigo Salazar, también denunció que la problemática de los RCD en Cali, va más allá: “Algunas escombreras ilegales son controladas por bandas delincuenciales, que cobran el ingreso de transportadores ilegales, llenan esos espacios con escombros y luego los lotean para la construcción de invasiones. El Jarillón del río Cauca, en el oriente de la ciudad, es una de las zonas más afectadas por esta práctica, donde se han construido cientos de viviendas en su cinturón ecológico”. 

  Desde la gerencia del Plan Jarillón, también se informó que, a lo largo de este corredor, total hay nueve puntos que están impactados por escombros: cuatro en la cara húmeda del Jarillón y cinco sobre la corona. 
  
  “Por toda esta situación, hago un llamado a los diferentes organismos de la Administración Distrital como el Dagma, a la Secretaría de Seguridad y Justicia, a la Unidad Administrativa Especial de Servicios Públicos y el Plan Jarillón para que, en un trabajo articulado, tomen las acciones correspondientes para clausurar las escombreras ilegales e implementen acciones encaminadas a fortalecer la cultura ciudadana en torno al manejo adecuado de los residuos”. 
  
  Para Salazar, también es prioritario la Administración Distrital pueda habilitar el nuevo punto limpio ubicado en predio El Corbatín, en corregimiento de Navarro, con el que se espera darle un cierre gradual a la Estación de Transferencia de la Carrera 50 con Autopista Simón Bolívar, en cumplimiento a la Sentencia 118 de 2010.
  
  “Este punto limpio tendrá un espacio de 40.000 metros cuadrados para la recepción, separación y clasificación de los RCD, con el plus de que allí se podrá hacer tratamiento y aprovechamiento de estos materiales para volverlos a reincorporar en procesos constructivos”, destacó. 
  
  Finalmente, subrayó que el cerramiento de Estación de Transferencia de la Carrera 50 también permitirá que la CVC pueda terminar el reforzamiento de 60 metros que faltan del dique del Plan Jarillón, así como el parque lineal de la carrera 50, que se proyecta construir hasta la Avenida Simón Bolívar.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          En Cali existen más de 10 escombreras ilegales que están causando un
          grave impacto ambiental
        </h1>
        <h5 className={style.Fecha}>Febrero 03 del 2024</h5>
        <ExpandableText text={Notice1} limit={300} />
        <h1 className={style.Tittle}>
          Bandas criminales controlan escombreras ilegales
        </h1>
        <ExpandableText text={Notice2} limit={300} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana2;
