import foto from "../../../assets/semilleros/interreligioso/interreligioso.png";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Interreligioso: React.FC = () => {
  const Notice1 = `La protección y promoción de la libertad de religión, creencias y conciencia es un pilar fundamental de los derechos humanos que debe ser defendido tanto a nivel nacional como internacional. Los Estados tienen la responsabilidad no solo de reconocer estos derechos, sino también de prevenir y abordar cualquier amenaza que pueda surgir contra ellos.

  A pesar del reconocimiento generalizado de la libertad religiosa en todo el mundo, es alarmante que aún existan personas que enfrenten persecución y discriminación debido a su religión o creencias. Estas realidades no solo vulneran los derechos individuales, sino que también pueden desencadenar tensiones y conflictos que socavan la estabilidad y la paz en las sociedades.
  
  Para abordar estas cuestiones de manera efectiva, es vital establecer sistemas legales y herramientas que promuevan y protejan la libertad de religión, creencias y conciencia en todas las regiones, incluida Latinoamérica. Colombia ha destacado en este aspecto, ya que su Constitución y la jurisprudencia de la Corte Constitucional garantizan y orientan específicamente estas libertades.
  
  La participación activa de Colombia en la Alianza Internacional para la Libertad de Religión y Creencias es un claro testimonio de su compromiso con la promoción de estos valores a nivel global. Además, la celebración del Día Nacional de la Libertad Religiosa y de Cultos en Colombia destaca la diversidad de creencias presentes en la sociedad y promueve la tolerancia y la coexistencia pacífica entre ellas.
  
  Eventos como este contribuyen significativamente a aumentar la conciencia sobre la importancia de respetar y proteger la libertad de religión y creencias en todos los ámbitos, fortaleciendo así los lazos sociales y fomentando la paz y la estabilidad tanto a nivel nacional como internacional.`;
  const Notice2 = `En el marco de la campaña "#ElCambioEsConVos", se destaca la intersección entre la religión y la paz, abordando aspectos tanto constitucionales como religiosos. Se reconoce la vital importancia de los valores morales para una nación y cómo estos se reflejan en constituciones y creencias religiosas.

  La Constitución, como acta fundacional y organizativa de una sociedad política, establece derechos, deberes ciudadanos y principios fundamentales. En Colombia, los derechos al culto y a la paz están consagrados en la Constitución, enfatizando la relación entre religión, espiritualidad y paz a nivel global, nacional y local.
  
  Desde una perspectiva religiosa, se subraya la conexión entre la paz y valores como amor, justicia, reconciliación y compasión, valores promovidos por diversas creencias. Se argumenta que la paz se logra al derribar barreras y fomentar el diálogo, la concordia y el respeto mutuo, incluso entre diferentes creencias religiosas.
  
  Los valores morales, aunque no exclusivos de las creencias religiosas, mantienen una estrecha relación, también reflejada en las constituciones. Se resalta la importancia de la justicia y la reconciliación para lograr una paz duradera, y se enfatiza la necesidad de comprensión y tolerancia para superar conflictos en un mundo globalizado que valora las expresiones y creencias individuales.
  
  En la campaña "#ElCambioEsConVos", el compromiso principal es el respeto irrestricto por los derechos humanos y las creencias profundas de todas las personas. Se reconoce la diversidad como enriquecedora y se promete un entorno donde todos puedan expresarse libremente y sin temor a la discriminación. Se busca fomentar una cultura de inclusión y tolerancia, donde cada voz y perspectiva sean respetadas. En este enfoque de cambio, todos los ciudadanos confían en la protección de sus derechos fundamentales y en el reconocimiento de sus identidades y creencias.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia 22 Agosto del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Libertad 19: Explorando el Resplandor de las Creencias en la
            Constitución Colombiana
          </h1>
          <h5 className={style.Fecha}>22 Agosto del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
      {/*Noticia Agosto 17 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Armonía Espiritual: Explorando la Intersección entre Religión y Paz
          </h1>
          <h5 className={style.Fecha}>22 Agosto del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Interreligioso;
