import foto2 from "../../../assets/semilleros/ambiental/amb2.png";
import foto1 from "../../../assets/semilleros/ambiental/amb1.png";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Ambiental: React.FC = () => {
  const Notice1 = `Afectaciones en la Salud Mental y Física por un Urbanismo Improvisado en Cali
  Las afectaciones en la salud mental y física de los caleños derivan de un urbanismo improvisado y sin control, especialmente en los desarrollos incompletos y las áreas céntricas. Este es un tema prioritario e inaplazable que debemos mejorar. La desarmonización entre urbanismo y naturaleza se manifiesta a diario con oleadas de calor.
  
  Desde el Estado Distrital, con una planificación adecuada, control físico de las autoridades y la concientización ciudadana, podemos mitigar sus efectos. El objetivo es tener, en el futuro, un hábitat urbano más amable.
  
  ¿Sabías que existen demasiadas áreas áridas que generan islas de calor? Hagamos un ejercicio rápido respondiendo a estas inquietudes:
  
  ¿Sienten que, al salir a la calle o al bajarse del bus MIO o del auto, experimentan un fogaje que parece quemar al encontrarse con un pavimento árido?
  Al llegar a sus casas, ¿quieren descansar pero no pueden dormir porque el calor es insoportable?
  ¿Han tocado las paredes que reciben el sol de la tarde y están ardientes hasta la madrugada?
  Si la respuesta a estas inquietudes es afirmativa, nuestra salud se puede estar viendo vulnerada. Sin entrar en detalles médicos, pero entendiendo que el medio ambiente es el espacio artificial, antrópico o natural donde vivimos, todos los factores que afectan la calidad de vida se pueden mitigar.
  
  Aquí entra la apuesta política de Rodrigo Salazar para gestionar las estrategias, planes y acciones que viabilicen un hábitat sostenible para Santiago de Cali.`;

  const Notice2 = `Santiago de Cali, con sus condiciones geográficas y ambientales, cuenta con quebradas, ríos, cordilleras y valles que la caracterizaban como un paraíso terrenal. Sin embargo, los desarrollos urbanísticos desaforados y la expansión urbana han impactado y destruido las rondas de los ríos, que eran corredores ambientales que unían la vegetación y fauna de las cordilleras central y occidental.

  A nivel de grandes impactos, el monocultivo ha desplazado el agua y la biodiversidad. Las rondas de los ríos y humedales, como los de Aguablanca, Pizamos, Pondaje y Charco Azul, han sido invadidas explosivamente, afectando nuestro patrimonio hídrico. Las obras de ingeniería han favorecido los intereses de los terratenientes, generando territorios para la siembra y construcción, lo que ha afectado el clima general de nuestro entorno.
  
  La construcción de edificios altos en las laderas y entradas de aire provenientes del Pacífico genera el efecto de ladera. La alta densidad de construcciones, aumentando las áreas duras y áridas como techos y pavimentos, genera alteraciones en la climatología urbana e incrementa el efecto de isla de calor. Este fenómeno, conocido como "bochorno", es en realidad un efecto invernadero, donde el aire caliente se concentra bajo una capa de aire frío o nubes, sin posibilidad de escape. Cuando se precipita, causa cambios bruscos de temperatura y humedad, derivando en enfermedades respiratorias.
  
  Por todo lo anterior, es urgente iniciar y apoyar políticas públicas para mejorar nuestro medio ambiente con el objetivo de lograr un hábitat sostenible en Cali. Es fundamental apoyar una propuesta de mejoramiento ambiental, estimulando la construcción de una cultura ambiental que gestione procesos y, conjuntamente con el estado distrital, desarrolle las acciones necesarias para la vida.
  
  Aurelio López Gaspar - Aleyda Chaves Figueroa
  
  `;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Julio 29 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto2} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            El urbanismo intensivo y las afectaciones en la calidad ambiental de
            los caleños.
          </h1>
          <h5 className={style.Fecha}>Julio 29 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
      {/*Noticia Julio 29 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>Mitigación de los extremos</h1>
          <h5 className={style.Fecha}>Julio 29 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Ambiental;
