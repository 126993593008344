import foto from "../../../assets/semilleros/adultomayor/adm1.jpg";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const AdultoMayor: React.FC = () => {
  const Notice1 = `Aumento de la Población de Adultos Mayores en Cali
  En Cali, la población de personas mayores ha experimentado un incremento notable, de acuerdo con el más reciente estudio presentado por "Cali Cómo Vamos" el 22 de noviembre de 2022. El informe resalta que mientras la población joven y de niños disminuye, la cantidad de personas en edades avanzadas está en constante aumento en la capital del Valle del Cauca. El estudio revela que actualmente, de cada 100 habitantes en Cali, 17 son personas mayores.
  
  Entre enero y julio de 2022, fallecieron 10,132 personas en la ciudad, lo que resulta en un número mayor de adultos que de nacimientos. Este aumento en la población mayor contrasta con una tasa de crecimiento poblacional que solía ser del 3% en años anteriores y que ahora se ha reducido al 1% (Revista Semana, 2022). Este fenómeno es resultado del desarrollo de la sociedad, donde el avance en la medicina ha incrementado la expectativa de vida.
  
  El aumento de la población de adultos mayores en la ciudad tiene diversas implicaciones en diferentes aspectos sociales, económicos y de servicios. Por ejemplo, un incremento en la población de adultos mayores puede poner presión en el sistema de salud de la ciudad, ya que es más probable que este grupo demográfico requiera atención médica más frecuente y especializada debido a problemas de salud relacionados con la edad, como enfermedades crónicas, afecciones cardíacas y deterioro cognitivo.
  
  Adicionalmente, con más adultos mayores, la demanda de programas de seguridad social, pensiones y servicios de jubilación aumentará. Esto pone de relieve la necesidad de políticas y programas adecuados para garantizar el bienestar financiero de esta población, especialmente si dependen en gran medida de las pensiones para su sustento.
  
  En términos generales, el aumento de la población de adultos mayores en Cali presenta una serie de desafíos y oportunidades que requieren una planificación cuidadosa y políticas adecuadas para garantizar su calidad de vida. Es fundamental apoyar a candidatos que contemplen las necesidades de la población de adultos mayores. Por esta razón, consideramos que Rodrigo Salazar Sarmiento cumplirá a cabalidad sus compromisos con esta población cuando sea concejal en Santiago de Cali.
  
  Revista Semana (2022). Estudio revela que en Cali ha aumentado la población de adultos mayores. Recuperado de:`;

  const Notice2 = `Protección de los Derechos y Deberes de las Personas Mayores
  La política de protección de los derechos y deberes de las personas mayores busca superar la situación actual de desventaja, discriminación y vulnerabilidad que enfrentan debido a los inadecuados estereotipos sociales alrededor de la vejez. Estos estereotipos confunden esta etapa del ciclo vital con enfermedad, pasividad, muerte y otros calificativos negativos.
  
  El artículo 13 de la Constitución Nacional establece: "Todas las personas nacen libres e iguales ante la ley, recibirán la misma protección y trato de las autoridades y gozarán de los mismos derechos, libertades y oportunidades sin ninguna discriminación por razones de sexo, raza, origen nacional o familiar, lengua, religión, opinión política o filosófica." Aunque este artículo es muy claro, desafortunadamente vivimos en una sociedad que aparentemente no envejece. A pesar de la existencia de leyes, artículos, políticas y decretos, parece ser cada vez menos importante la etapa de la vejez.
  
  Diversos factores, como el control de la natalidad y la violencia, contribuyen al aumento de la población de personas mayores con el paso de los años. Este incremento representa un gran desafío para los dirigentes y aspirantes políticos, quienes deben ir a la par del proceso natural del ciclo vital.
  
  Más programas, proyectos y el cumplimiento correcto de las leyes pueden convertirse en la solución a muchos de los inconvenientes que enfrentan a diario las personas mayores. Porque el cambio inicia en cada uno de nosotros… EL CAMBIO ES CON VOS.`
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Agosto 17 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Estudio Revela un Incremento en la Población de Adultos Mayores en
            Cali
          </h1>
          <h5 className={style.Fecha}>Agosto 17 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
          <a href="https://www.semana.com/nacion/cali/articulo/estudio-revela-que-en-cali-ha-aumentado-la-poblacion-de-adultos-mayores/202253/">
            Revista Semana (2022)
          </a>
        </div>
      </section>
      {/*Noticia Agosto 14 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Esta vez hablaremos de los deberes y derechos de las personas
            mayores…
          </h1>
          <h5 className={style.Fecha}>Agosto 14 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default AdultoMayor;
