import style from "../blog.module.css";
import foto from "../../../assets/blog/junio-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana11: React.FC = () => {
  const Notice1 = `El concejal Rodrigo Salazar presentó en plenaria tres propocisiones ante el honarble Concejo de Cali, para realizar control político a la entidades Unidad Administrativa Especia de Servicios Públicos y el Departemento Administrativo de Gestión del Medio Ambiente DAGMA.
   
Durente la plenaria se readicó una proposición de citación para convocar al Director de la Unidad Administrativa Especial de Servicios Públicos (UAESP), Walter Camilo Murcia Lozano, con el objetivo de discutir temas referentes con las Organizaciones de Recicladores de acciones de Oficio, quienes son considerados sujetos de especial protección constitucional. Además, se abordará la tarifa por el servicio de aprovechamiento de residuos solidos. 
En concordancia con lo anterior y en ejercicio de sus funciones, el concejal solicita a la UAESP responder preguntas relacionadas con las bodegas de reciclaje, barrios que cuenten con rutas selectivas de aprovechamiento de residuos, mejoramiento de la capacidad operativa, empresas autorizadas para el procesamiento de residuos sólidos, entre otras.
Por otro lado, Salazar llamó a control al director del Departamento Administrativo de Gestión de Medio Ambiente, Mauricio Mira. Y subrayó la importancia de esta citación destacando la necesidad de evaluar y optimizar las políticas y acciones en favor del medio ambiente urbano. Se busca que la entidad de respuestas de como asegurar que las áreas verdes de la ciudad de Cali se gestionen de manera sostenible y se conserven para el disfrute de todos los ciudadanos.
Además, al funcionario se le solicitó en detalle las acciones de control y mitigación del riesgo que se están implementando en el cerro de Bataclán, saber si hay programas de reforestación, contención a la expansión de los asentamientos humanos de desarrollo incompleto que invaden terrenos del ecoparque de Bataclán.
Estas proposiciones se hicieron desde la curul de El Cambio es con Vos con el fin de poder saber de parte de estas importantes entidades acciones adelantadas para el mejoramiento de los entornos en Cali.
`;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="Foto"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Concejal Rodrigo Salazar Impulsa Control Político a la UAESP y al
          DAGMA: Enfoque en Recicladores y Áreas Verdes de Cali
        </h1>
        <h5 className={style.Fecha}>Junio 14 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana11;
