import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import Style from "./navbar.module.css";
//import Live from "./Live";
import Unete from "./Unete";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const handleInicio = () => {
    navigate("/");
  };
  const handleKnowme = () => {
    navigate("/Conoceme");
  };
  const handleSemilleros = () => {
    navigate("/Semilleros");
  };
  // const handleMision = () => {
  //   navigate("/Mision");
  // };
  const handleNoticias = () => {
    navigate("/Noticias");
  };

  return (
    <main className={Style.Navbar}>
      <nav className={Style.NavbarBox}>
        <div className={Style.BoxLogoBotonRS}>
          <section className={Style.BoxImgLogoRS}>
            <img
              className={Style.LogoRS}
              src={logo}
              draggable={false}
              alt="logo"
              onClick={handleInicio}
            />
            {/* <Live /> */}
          </section>
          <section className={Style.BotonRSmobile}>
            <Unete />
          </section>
        </div>
        <section className={Style.MenuRS}>
          <div className={Style.NaveRS} onClick={handleInicio}>
            Inicio
          </div>
          <div className={Style.NaveRS} onClick={handleKnowme}>
            Conóceme
          </div>
          <div className={Style.NaveRS} onClick={handleSemilleros}>
            Semilleros
          </div>
          {/* <div className={Style.NaveRS} onClick={handleMision}>
            Misión
          </div> */}
          <div className={Style.NaveRS} onClick={handleNoticias}>
            Noticias
          </div>
        </section>
        <section className={Style.BotonRSweb}>
          <Unete />
        </section>
      </nav>
    </main>
  );
};

export default Navbar;
