import style from "./frase.module.css";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Animated as Animatedx } from "react-animated-css";

const FraseUtils: React.FC = () => {
  const [isVisibleFrase, setIsVisibleFrase] = useState(true);
  //Nuestra existencia está dada  por un propósito superior, el de servir
  return (
    <main className={style.MasterFrase}>
      <VisibilitySensor
        partialVisibility
        onChange={(isVisibleFrase: boolean) =>
          setIsVisibleFrase(isVisibleFrase)
        }
      >
        <Animatedx
          {...{
            animationIn: "fadeInDown",
            animationOut: "fadeOut",
            animationInDuration: 3000,
            // animationOutDuration: 10000,
            isVisible: isVisibleFrase,
          }}
        ><div className={style.Frasebanner}>
        <p className={style.Frase}>
          Nuestra existencia está dada
          <br />
          por un propósito superior,
          <br />
          <b className={style.FraseDestacada}>el de servir</b>
        </p>
      </div></Animatedx>
      </VisibilitySensor>
      
    </main>
  );
};

export default FraseUtils;
