import Style from "./footer.module.css";
import logo from "../../assets/LogoFo.svg";
import PlaceIcon from "@mui/icons-material/Place";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import logot from "../../assets/tik.png";
import Contador from "../Contador";

const Footer = () => {
  const handleMapa = () => {
    window.location.replace(
      "https://www.google.com/maps/place/Sede+Concejo+Distrital/@3.4544729,-76.5367011,17z/data=!3m1!4b1!4m6!3m5!1s0x8e30a66f7aa386b1:0x9c19a9d7150b9757!8m2!3d3.4544675!4d-76.5341262!16s%2Fg%2F11dxm6y0lp?authuser=0&entry=ttu"
    );
  };

  const handleFacebook = () => {
    window.location.replace("https://www.facebook.com/rodrisalazarco");
  };
  const handleTwitter = () => {
    window.location.replace("https://twitter.com/rodrisalazarco");
  };
  const handleInsta = () => {
    window.location.replace("https://www.instagram.com/rodrisalazarco");
  };
  const handleTik = () => {
    window.location.replace("https://www.tiktok.com/@rodrisalazarco");
  };

  const handlewpp = () => {
    window.location.replace("https://api.whatsapp.com/send?phone=573115429203");
  };

  const handleUnete = () => {
    window.location.replace("https://forms.gle/LZRzfBsCVa41svmB8");
  };

  return (
    <footer className={Style.Master}>
      <section className={Style.Columms}>
        <main className={Style.BoxColumms}>
          <img className={Style.logo} src={logo} draggable={false} alt="logo" />
          <div className={Style.buttom} onClick={handleUnete}>
            UNIRSE
          </div>
        </main>
        <main className={Style.BoxColumms}>
          <h3 className={Style.titulo}>Ubicación</h3>
          <div className={Style.datosx}>Sede Concejo Distrital.</div>
          <div className={Style.datosx}>Cali, Valle del Cauca.</div>
          <div className={Style.rutax} onClick={handleMapa}>
            <PlaceIcon sx={{ mr: "5px" }} />
            Ver en mapa
          </div>
        </main>
        <main className={Style.BoxColumms}>
          <h3 className={Style.titulo}>Síguenos en</h3>
          <div className={Style.datosy}>@RodriSalazarCo</div>
          <div className={Style.redes}>
            <div onClick={handleFacebook} style={{ cursor: "pointer" }}>
              <FacebookIcon
                sx={{
                  color: "white",
                  mr: "20px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.5)",
                  },
                }}
              />
            </div>
            <div onClick={handleTwitter} style={{ cursor: "pointer" }}>
              <XIcon
                sx={{
                  color: "white",
                  mr: "20px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.5)",
                  },
                }}
              />
            </div>
            <div onClick={handleInsta} style={{ cursor: "pointer" }}>
              <InstagramIcon
                sx={{
                  color: "white",
                  mr: "20px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.5)",
                  },
                }}
              />
            </div>
            <div onClick={handleTik} style={{ cursor: "pointer" }}>
              <img className={Style.tik} alt="tiktok" src={logot} />
            </div>
          </div>
        </main>
        <main className={Style.BoxColumms}>
          <h3 className={Style.titulo}>Contáctanos</h3>
          <div
            className={Style.wpp}
            style={{ cursor: "pointer" }}
            onClick={handlewpp}
          >
            <WhatsAppIcon
              sx={{
                color: "white",
                mr: "20px",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
            />
            (+57) 3115429203
          </div>
          <Contador />
        </main>
      </section>
      <section className={Style.Copy}>
        <div className={Style.boxcopy}>Copyright © 2024 Rodrigo Salazar</div>
      </section>
    </footer>
  );
};

export default Footer;
