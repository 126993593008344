import { Routes, Route } from "react-router-dom";
import Landing from "../containers/Landing";
import Knowme from "../containers/Knowme";
import Noticias from "../containers/Noticias";
import Semilleros from "../containers/Semilleros";
// import Mision from "../containers/Mision";
import Template from "../components/Template";
import LinksUtils from "../containers/Links";


const Rutas: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Template><Landing /></Template>} />
      <Route path="/" element={<Template><Landing /></Template>} />
      <Route path="/Conoceme" element={<Template><Knowme /></Template>} />
      <Route path="/Semilleros" element={<Template><Semilleros /></Template>} />      
      <Route path="/Noticias" element={<Template><Noticias /></Template>} />
      <Route path="/Enlaces" element={<LinksUtils/>} />
    </Routes>
  );
};

export default Rutas;
