import style from "./publicidad.module.css";
import foto from "../../assets/banner/PublicidadEmprendimiento.png";
import fotomovil from "../../assets/banner/BannerEmprendedoresmovil.png"

const Publicidad: React.FC = () => {
  const handleUnete = () => {
    window.location.replace("https://forms.gle/LZRzfBsCVa41svmB8");
  };
  return (
    <main className={style.BannerFondoPublic}>
      <img
        className={style.ImgPublicidad}
        src={foto}
        alt="Publicidad"
        draggable={false}
      />
      <img
        className={style.ImgPublicidadMovil}
        src={fotomovil}
        alt="Publicidad"
        draggable={false}
      />
      <div className={style.PublicButtom} onClick={handleUnete}>AQUí</div>
    </main>
  );
};

export default Publicidad;
