import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana16: React.FC = () => {
  const Notice1 = `
En debate de control político al secretario de Desarrollo Económico, Edwin Maldonado, adelantado en el Concejo Distrital por proposición del Concejal Rodrigo Salazar, se planteó la preocupación por el comportamiento del desempleo en la ciudad, que según cifras del Departamento Administrativo Nacional de Estadística – DANE, viene sosteniendo una tendencia de aumento durante todo el primer semestre de 2024. Una situación apremiante considerando que las otras ciudades capitales del país presentan comportamientos favorables en la generación de empleo durante el mismo lapso según análisis de la Cámara de Comercio de Cali.

El cabildante además manifestó que, si bien los esfuerzos por difundir la oferta disponible de empleos de las agencias de empleo a través de ferias de empleabilidad, revela una buena intención de la administración por conectar la oferta con la demanda de trabajo, no es suficiente para responder al reto de generar 100 mil empleos en la ciudad como lo ha propuesto el alcalde Alejandro Eder. Ahora bien, en las ferias adelantadas por la Alcaldía a la fecha se han promovido más de 4.500 vacantes, pero las cifras del DANE presentan una destrucción de 45.000 puestos de trabajo en lo corrido del año en Cali.

Cifra en la que la Alcaldía también tiene responsabilidad directa, toda vez que la planta de personal pasó de 12.000 prestadores de servicio en Diciembre, a poco más de 8.000 en Junio.
“Más allá del discurso político, las cifras del DANE arrojan una realidad angustiante para miles de familias” – afirmó el concejal Salazar.

¿Qué pasa con el Fondo Solidario?

Otro tema importante sobre el que se refirió el honorable Concejo fue frente al Fondo Solidario y de
Oportunidades (FSO) administrado por la Secretaría de Desarrollo Económico en Cali, a través del
cual la Alcaldía puede entregar recursos financieros para apoyar a microempresarios,
comerciantes, vendedores informales y emprendedores, vía capital semilla, capital de trabajo, o
microcrédito. A la fecha este instrumento de financiación creado por el Concejo vía acuerdo distrital
en el año 2021, y que cuenta con financiación específica y garantizada con el 5% de estampilla
prodesarrollo, se encuentra paralizado y no se ha hecho nuevos desembolsos toda vez que no se
ha contratado al operador financiero correspondiente.
En el debate se precisó que los $21.000 millones que el alcalde anunció meses atrás haber
gestionado, en realidad corresponden a recursos que ya estabanaforados vía estampilla, y a los
cuales solo se han incorporado $600 millones nuevos de libre destinación. Frente al tema varios
concejales alzaron su voz de protesta, manifestando la importancia de ejecutar los recursos
disponibles por parte de la Secretaría de Desarrollo Económico, señalando la baja inversión que
registra esa dependencia a la fecha.
Así mismo, se insistió en que la línea de microcrédito es la principal estrategia del Fondo Solidario
y de Oportunidades, especialmente para luchar contra el flagelo del “gota a gota”. En el informe
presentado por el secretario se evidencia un recaudo de $4.430 millones (28%) y pendiente por
recaudar $11.401 millones (72%) del total de la cartera que corresponde a $15.831 millones. sin
embargo, en el cierre del 2023 la Secretaría reportó el mismo valor de la cartera y un recaudo por
valor de $4.329 millones ($2.978 delcapital y $1.236 en intereses), con lo cual, solo habrían
recaudado $101 millones durante todo 2024.
Ha faltado mayor diligencia por parte de la Alcaldía de Cali en la gestión de la cartera del Fondo
Solidario para no dejar marchitar esta herramienta, y también en la contratación de un nuevo
operador para poder irrigar los recursos que necesitan los empresarios, comerciantes y
emprendedores.

`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/C-LTGtiOODZ/?igsh=cmNraGkxdW8zMjh4"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Alcaldía de Cali no despega en generación de empleo. Se perdieron
          45.000 puestos en el primer semestre 2024 según informe del DANE
        </h1>
        <h5 className={style.Fecha}>Agosto 5 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana16;
