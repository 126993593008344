import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCOthkMErg-z8ye_ILeJcFzIAfbX9Ligy4",
  authDomain: "rsccali-cf228.firebaseapp.com",
  projectId: "rsccali-cf228",
  storageBucket: "rsccali-cf228.appspot.com",
  messagingSenderId: "423223322473",
  appId:"1:423223322473:web:2059dff14dfd7460e0bff8",
  measurementId: "G-NL0BPRXJKZ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getStorage = async () => {
  const docRef = doc(db, "gestorvisitas", "contador");
  const resp = await getDoc(docRef);
  const spawn = resp.get("visit");
  return spawn;
};

export const setStorage = async (save: number) => {
  const docRef = doc(db, "gestorvisitas", "contador");
  await setDoc(docRef, { visit: save });
};
