import FirstSocial from "../../components/FirstSocial";
import Buttondown from "../../components/Buttondown";
import Frase from "../../components/Frase";
import Carrusel from "../../components/Carrusel";
import style from "./landing.module.css";
import Cards from "../../components/Cards";
import Redes from "../../components/Redes";
import Eslogan from "../../components/Eslogan";
import Helmets from "../../widgets/Helmet";
import WhatsappButton from "../../components/whatsappbutton";
import Publicidad from "../../components/Publicidad";
import RecursoConcejal from "../../components/RecursosConcejal";
//import LiveRedes from "../../components/LiveRedes";

const Landing: React.FC = () => {
  return (
    <>
      <Helmets />
      <main className={style.Master}>
        {/* la foto tiene que tener un tamaño minimo de 1080p */}
        {/* Buscar una fuente similar a wellcome home para la tipografia del cambio es con vos */}
        <div className={style.Fotobanner}>
          <div className={style.FotoShadow}>
            <Frase />
            <Eslogan />
            <div className={style.Buttondwn}>
              <Buttondown />
            </div>
          </div>
        </div>
      </main>
      {/* <LiveRedes urlx="https://fb.watch/tx0TVQu3Z2/"/> */}
      <RecursoConcejal />
      <Publicidad />
      <Cards />
      <Carrusel />
      <FirstSocial />
      <div className={style.BoxRedesFixed}>
        <Redes />
      </div>
      <WhatsappButton />
    </>
  );
};

export default Landing;
