import styles from "./buttondown.module.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Buttondown: React.FC = () => {
  const scrollToBottom = () => {    
    window.scrollTo({
      top: window.scrollY + window.innerHeight,
      behavior: "smooth", // Opcional: hace que el desplazamiento sea suave
    });
  };

  return (
    <>
      <button onClick={scrollToBottom} className={styles.buttondown}>
        <KeyboardArrowDownIcon />
      </button>
    </>
  );
};

export default Buttondown;
