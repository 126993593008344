import foto from "../../../assets/semilleros/juventud/juventud.png";
import foto1 from "../../../assets/semilleros/juventud/juv1.jpg";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Juventud: React.FC = () => {
  const Notice1 = `La creciente cifra de homicidios de jóvenes en Cali durante 2023 resalta la urgente necesidad de implementar medidas efectivas que aborden esta problemática. Según el Observatorio de Seguridad, se han registrado 186 asesinatos de jóvenes en lo que va del año, marcando un incremento respecto a los 176 de 2022 (Revista Semana, 2023).

  Estas cifras alarmantes plantean una cuestión apremiante: ¿cómo pueden prevenirse estas tragedias y qué acciones deben tomarse para lograrlo? Detrás de esta ola de violencia juvenil se reconocen socialmente dos grandes asuntos: la venta de sustancias estupefacientes y la lucha por el control territorial.
  
  Para combatir esta problemática de manera efectiva, se necesita una estrategia integral que enfoque no solo la represión de las actividades criminales, sino también la prevención y la creación de oportunidades alternativas para los jóvenes.
  
  Desde el Concejo de Cali, es crucial liderar el cambio a través de políticas y programas específicos que aborden directamente las causas fundamentales de estos crímenes. La concentración de asesinatos en áreas específicas, como las comunas 13, 15 y 21, subraya la necesidad de operativos consistentes en el tiempo, pero también de abordar las condiciones subyacentes que perpetúan esta violencia (Revista Semana, 2023).
  
  Desde el comité de jóvenes, se reconoce la importancia de un enfoque multidisciplinario que involucre la colaboración entre agencias gubernamentales, organizaciones comunitarias y expertos en seguridad ciudadana, así como la ciudadanía en general.
  
  Desde la curul del futuro concejal Rodrigo Salazar, se espera priorizar y apoyar todos los programas y estrategias enfocados en la inversión en educación, programas de formación laboral y oportunidades recreativas, que puedan ayudar a desviar a los jóvenes de entornos hostiles y darles opciones viables para su futuro.
  
  Referencias: Revista Semana (2023) - "Homicidios de jóvenes en Cali se han incrementado este año: revelaron las causas que desataron esta ola de crímenes."`;
  const Notice2 = `En el Día Internacional de la Juventud, celebramos el inmenso potencial y la vitalidad de nuestra juventud. Desde el Concejo de Cali, nos comprometemos a trabajar en la creación del Observatorio y la Secretaría de Juventud. Estas instituciones serán fundamentales para empoderar a los jóvenes como agentes de cambio en nuestra ciudad.  `;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia  Agosto 28 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>
            Crisis en crecimiento: Homicidios de Jóvenes en Cali
          </h1>
          <h5 className={style.Fecha}> Agosto 28 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
          <a href="https://www.semana.com/nacion/cali/articulo/homicidios-de-jovenes-en-cali-se-han-incrementado-este-ano-revelaron-las-causas-que-desataron-esta-ola-de-crimenes/202321/">
            Reviste Semana (2023)
          </a>
        </div>
      </section>
      {/*Noticia  Agosto 12 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto1} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>Dia de la juventud</h1>
          <h5 className={style.Fecha}> Agosto 12 del 2023</h5>
          <ExpandableText text={Notice2} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Juventud;
