import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana15: React.FC = () => {
  const Notice1 = `
  Durante la sesion plenaria en el hemiciclo del Concejo de Cali se llevo a cabo la gran exaltalción al centro comercial UNICO Outlet y a la Fundación UNICO con el fin de destacar la ardua labor de estas organizaciones con sus diversos programas de responsabilidad social empresarial.
  
El concejal Rodrigo Salazar ponente de la exaltación enfatizó la importancia que empresas radicadas en Cali como el cc UNICO se interesen por desarrollar mecanimos que mejoren la calidas de vida de las comunidades que los rodean.

Así mismo, se contó con la participación del CEO de UNICO, el señor José Toledo quien afirmó que “gracias al honorable Concjeo de Cali por esta exaltación seguiremos comprometidos mucho más con nuestro trabajo social”.

Por su parte Carlos Lara, director de la Fundación UNICO resaltó durante su discuro que “hemos logrado impactar a casi 40 mil niños de toda Coloambia gracias al trabajo en equipo con otras organizaciones tanto publicas, privadas y estatales, también se ha atendido a cerca de 8 mil niños en programas de salud junto con Fundación Casa de Colombia, este es un momento especial para agradecer a todos los que han confiado en nuestro equipo de trabajo que le apuesta a un mejor futuro para el pais, manifestó Lara.

El cabildante Rodrigo Salazar también resaltó que dentro de las acciones ciudadanas hay un ejercicio de coresponsabilidad entre los organimos del Estado y organizaciones civiles y empresariales. “Desde mi curul se hará los reconocimientos merecidos a aquellas empresas y organizaciones civiles que impactan no solo desde lo económico sino por el desarrollo social que tienen en las comunidades y territorios en los que se ubican siendo grandes ejemplos de la responsabilidad social empresarial La señorita Sofia Soto una benefeiciaria del programa After School cerró las interveciones durante la plenraia, quien expresó sus agradecimientos a los concejales y al concejal Rodrigo Salazar.

“He estado vinculada al programa por 7 años solo tengo palabras de agradecimiento por todo lo que he aprendido en las clases de orientación psicosicial pues nos han dado las herramientas necesarias para manejar muchas de las problemáticas que afronta la sociedad hoy en día, estas experiencias nos han dejado una huella imborrable”, compartió la beneficiaria.

A la ceremonia asistieron representantes de organizaciones civiles y ediles de las comunas 5 y 6, asi como padres de familia y vecinos del centro comercial.

La ceremonia finalmente se cerró con la entrega de los pergaminos por parte del concejal Rodrigo Salazar y el presidente del Concejo de Cali, Carlos Arias.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C-D9-ztSFaZ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          El Concejo de Cali exaltó al Centro Comercial UNICO Outlet y Fundación UNICO por sus programas de responsabilidad social empresarial
        </h1>
        <h5 className={style.Fecha}>Julio 29 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana15;
