import style from "./eslogan.module.css";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Animated as Animatedx } from "react-animated-css";

const Eslogan: React.FC = () => {
  const [isVisibleEslogan, setIsVisibleEslogan] = useState(true);
  return (
    <main className={style.EsloganMaster}>
      <VisibilitySensor
        partialVisibility
        onChange={(isVisibleEslogan: boolean) =>
          setIsVisibleEslogan(isVisibleEslogan)
        }
      >
        <Animatedx
          {...{
            animationIn: "fadeInUp",
            animationOut: "fadeOut",
            animationInDuration: 3000,
            // animationOutDuration: 10000,
            isVisible: isVisibleEslogan,
          }}
        >
          <div className={style.EsloganBox}>
            <p className={style.EsloganTexto}>
              El Cambio es{" "}
              <b
                style={{
                  color: "#FFDF3F",
                }}
              >
                con vos
              </b>
            </p>
            {/* <p>E</p>
    <p>l</p>
    <p>C</p>
    <p>a</p>
    <p>m</p>
    <p>b</p>
    <p>i</p>
    <p>o</p>
    <p>e</p>
    <p>s</p>
    <p>c</p>
    <p>o</p>
    <p>n</p>
    <p>v</p>
    <p>o</p>
    <p>s</p> */}
          </div>
        </Animatedx>
      </VisibilitySensor>
    </main>
  );
};

export default Eslogan;
