import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana19: React.FC = () => {
  const Notice1 = `Con la firma de memorando de cooperación se formalizó la alianza entre varios concejales de Cali, Jamundí, Villa Rica y Puerto Tejada, para la promoción y consolidación del proyecto Área Metropolitana del Suroccidente colombiano AMSO. Este proceso es un importante hito histórico en el ordenamiento territorial y la planificación de nuestra ciudad y de toda la región, que será refrendado a través de una Consulta Popular, como lo determina la ley de áreas metropolitanas, el próximo Domingo 24 de noviembre, de acuerdo con la resolución 6042 de 2024 de la Registraduría Nacional de Colombia.

Esta consulta popular les preguntará a los ciudadanos de los siete municipios que conforman el área metropolitana si está de acuerdo, o no, con la conformación de dicho modelo asociativo entre entes territoriales. Por esta razón para los concejales es muy importante promover la participación ciudadana, pero al mismo tiempo hacer control político a las acciones que desarrollen las diferentes alcaldías en su obligación constitucional de socializar y hacer pedagogía electoral con los ciudadanos que viven en estos siete municipios.

Igualmente, esta Alianza que ha nacido busca sumar la cooperación y el apoyo de todos los concejales y bancadas de los siete Concejos municipales, promoviendo el debate objetivo sobre las ventajas de la conformación del área metropolitana, de tal manera que se puedan aclarar las inquietudes que eventualmente circulen en la opinión pública frente a tan importante proyecto.

“El modelo del área metropolitana permitirá la planificación y articulación de la gestión territorial, por lo que permitirá aumentar la competitividad y hasta dar luz a proyectos ambientales como la recuperación del rio Cauca, el reto principal es que las poblaciones sean conscientes de las ventajas de esta figura y salga a votar informados”, manifestó el concejal verde Rodrigo Salazar, promotor del voto positivo en Cali.

Esta asociación de municipios arrancó en el año 2022 con un memorando de entendimiento y se consolidó en el año 2023 con la aprobación del Congreso de la República, en su Comisión de Ordenamiento Territorial (COT), y permitirá mejorar la competitividad regional en distintos aspectos estratégicos como la seguridad, la movilidad, la gestión del medio ambiente, el ordenamiento territorial, y la generación de vivienda. Cabe resaltar que la figura de áreas metropolitanas ya ha sido implementada desde hace muchos años en otras regiones del país como Medellín con el Valle de Aburrá, o Bucaramanga y Manizales con sus áreas metropolitanas, por lo que representa una gran oportunidad para nuestra región y convertirnos en todo un referente nacional e internacional.
“El área metropolitana es una gran oportunidad para dar solución a problemáticas que desde hace años han afectado la región, como lo es por ejemplo en Jamundí el tema del transporte intermunicipal que le sale muy costoso a la ciudadanía”, expresó Ivonne Giraldo, concejal de Jamundí.
El departamento del Cauca con sus municipios de Villa Rica y Puerto Tejada también tiene incidencia en este proyecto.
“El AMSO permitiría dar solución al problema del agua potable y el mal manejo de los residuos sólidos, no le tengamos miedo a esta figura”, comentó Andrés Felipe Possu, concejal de Villa Rica. 
Con el eslogan “Juntos somos más fuertes” esta alianza intermunicipal buscará dinamizar la opinión pública y a las alcaldías, hacia el diálogo y la reflexión del área metropolitana.

  `;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/C_lYFlQuHXL/?utm_source=ig_web_copy_link"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Concejales de la región se abanderan frente a la propuesta del Área
          Metropolitana del Suroccidente colombiano - AMSO
        </h1>
        <h5 className={style.Fecha}>Septiembre 06 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana19;
