import foto from "../../../assets/semilleros/cultura/cultura.png";
import style from "../semilla.module.css";
import ExpandableText from "../TextoExpandible";

const Cultura: React.FC = () => {
  const Notice1 = `La cultura, la economía y el territorio están interconectados en la organización humana. La cultura se compone de elementos heredados, influencias del contexto y resignificaciones constantes. Carga de sentido la vida humana con tradiciones, conocimientos y manifestaciones en distintas dimensiones, cumpliendo funciones sociales y económicas, proporcionando imaginarios y sustentando la calidad de vida.

  El territorio es un ecosistema de artistas y cultores cuya riqueza cultural y artística dinamiza las comunidades. Sin embargo, hay carencias en el acceso a la información y en la formalización de la industria creativa. Es necesario reflexionar sobre la industria cultural y el territorio, buscando bases sólidas y conexiones para el desarrollo de los proyectos culturales.
  
  ¿Cómo lograr su consolidación económica? Se requiere formalización, sostenibilidad económica y uso de herramientas tecnológicas. Como cultores, debemos encontrar el equilibrio entre el amor por la cultura y la circulación de recursos para sostener los proyectos artísticos y permitir el desarrollo laboral en el arte.
  
  Es necesario reflexionar y generar estrategias de conexión con las industrias, instituciones y la cadena de valor para crear alternativas que mejoren las condiciones de vida a través del arte.`;
  return (
    <main className={style.BoxNotice}>
      {/*Noticia Julio 06 del 2023*/}
      <section className={style.Noticia}>
        <div className={style.Foto}>
          <img className={style.FotoImg} src={foto} alt="FotoSemillero" />
        </div>
        <div className={style.Separador}></div>
        <div className={style.NoticeBox}>
          <h1 className={style.Tittle}>Cultura, economía y territorio</h1>
          <h5 className={style.Fecha}>Julio 06 del 2023</h5>
          <ExpandableText text={Notice1} limit={400} />
        </div>
      </section>
    </main>
  );
};

export default Cultura;
