import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import foto from "../../../assets/blog/Septiembre-19-2024.jpg";

const Semana21: React.FC = () => {
  const Notice1 = `Concejales de la región del suroccidente colombiano junto a miembros del Gobierno Nacional y organismos de la Alcaldía de Cali asistieron al conversatorio “Áreas Metropolitanas en Colombia: Retos y Oportunidades en la Planificación Territorial”, en el marco de generar un espacio de diálogo entre cabildantes del Valle del Cauca y Cauca en torno al estudio promoción y consolidación del proyecto Área Metropolitana del Suroccidente colombiano AMSO.

Este proceso es un importante hito histórico en el ordenamiento territorial y la planificación de la ciudad y de toda la región, que será refrendado a través de una Consulta Popular, como lo determina la ley de áreas metropolitanas, el próximo Domingo 24 de noviembre, de acuerdo con la resolución 6042 de 2024 de la Registraduría Nacional de Colombia.

El objetivo de esta cumbre es generar un debate objetivo e informado acerca de las ventajas de la conformación del área metropolitana, así como la creación de una pedagogía para la comunidad y la opinión pública que despeje las dudas frente a este magno proyecto que está a casi dos meses de su consulta a la ciudadanía de los siete municipios.

Diego Hau, secretario de Gobierno de la Alcaldía de Cali, considera que el conversatorio fue muy productivo ya que gracias a la gestión del concejal Rodrigo Salazar se logró convocar a cabildantes de los siete municipios del AMSO y dar conocer las ventajas de que un área metropolitana traería en términos de desarrollo.

“Es una oportunidad para mejorar problemas de movilidad, seguridad ciudadana, además de potencializar asuntos socioculturales y la economía de esta región tan importante, por eso, estaremos votando sí al AMSO”, manifestó el jefe de esa cartera.

Para Rodrigo Salazar, concejal de Cali por el Partido Alianza Verde, ve muy positivo que se den estos espacios entre los entes regionales y nacionales para garantizar un modelo para el desarrollo territorial respetando las autonomías de cada territorio.

“Se lograron aclarar inquietudes que tenían los concejales frente al proceso de asociatividad e ir nutriéndonos en esa perspectiva de defender este modelo necesario para la planificación regional, ser más competitivos y cerrar brechas que tenemos como región y que sea competente, no se van a crear nuevas entidades territoriales sino a asociarnos y a respetar las autonomías de cada municipio, por eso, estamos muy comprometidos con hacer realidad el Área Metropolitana del Suroccidente”, compartió el cabildante verde.

Para Ximena Londoño, concejal de Jamundí es vital que se sigan realizando estos conversatorios respecto al AMSO ya que como representantes pueden transmitir una información más clara a las comunidades.

“El proyecto como tal es muy viable para el beneficio de la comunidad jamundeña, para tratar temas como soluciones a la movilidad, tema álgido en el municipio, también debemos hacer análisis profundos y financieros”, agregó la concejala Londoño.

Según Diego Fernando Caballero, concejal del municipio de Dagua, ve con buena pinta la conformación del área metropolitana, “quisiéramos saber cuáles serían los aportes de Dagua al AMSO, somos un municipio con potencial en biodiversidad, lo cual creemos que ese sería un punto importante, yo considero que esta unión es una gran oportunidad para que Dagua se visibilice más”, sostuvo Caballero. 
 
Este espacio de diálogo contó con una asistencia de alrededor de 50 concejales de los municipios de Jamundí, Dagua, Palmira, Candelaria, Villa Rica, Puerto Tejada y Distrito de Cali, además de delegados del Gobierno Nacional como Ministerio del Interior, Registraduría Nacional, Alcaldía de Cali, Planeación Distrital, Secretaría de Gobierno y asesores.

Durante el encuentro concejales y demás organizaciones presentes hablaron sobre proyectos movilizadores a poder desarrollarse en la región si se llega a aprobar el Área Metropolitana del Suroccidente, tales como la recuperación ambiental del río Cauca, mejoramiento y estrategias de movilidad y seguridad y de participaciones en la COP 16, por su parte, los delegados de la Registraduría Nacional explicaron la dinámica para el día de la consulta popular. 

Tras este primer conversatorio se llegó a la conclusión y compromiso de generar más espacios pedagógicos que involucre también a la ciudadanía de los siete municipios, para que voten la consulta de una manera informada y se evite el abstencionismo. 

  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoAmso"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Valle del Cauca y Cauca en camino hacia la conformación del Área
          Metropolitana del Suroccidente AMSO
        </h1>
        <h5 className={style.Fecha}>Septiembre 19 del 2024</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana21;
