import style from "../blog.module.css";
import { InstagramEmbed } from "react-social-media-embed";
import ExpandableText from "../TextoExpandible";

const Semana1: React.FC = () => {
  const Notice1 = `Por una Cali limpia y para devolverle la dignidad al Distrito, el
  concejal Rodrigo Salazar Sarmiento del Partido Alianza Verde, hizo un
  llamado al Gobierno del Cambio para que la Superintendencia de
  Servicios Públicos entregue a la ciudad de Santiago de Cali, el
  servicio de recolección, transporte y disposición final de los
  residuos sólidos. El concejal Salazar argumentó que el gobierno de
  Álvaro Uribe Vélez, intervino la empresa de aseo 'Emsirva' de manera
  arbitraria e ilegítima. La soberanía de Cali fue arrebatada desde hace
  15 años, siendo la única ciudad en Colombia que no cuenta con la
  operación de aseo manejada por la entidad territorial, afectando el
  medio ambiente y a la misma ciudadanía, con la mala operación del
  servicio de aseo. Anticipando que el próximo 4 de febrero del 2024, se
  vencen los contratos con las empresas operadoras de aseo, el concejal
  del Partido Alianza Verde, solicitó "un debate de control político, a
  la Administración Distrital para que se hagan las acciones
  pertinentes, que permitan recuperar la operación del aseo para Cali".
  Así mismo, el concejal Rodrigo Salazar, pidió al gobierno del alcalde
  Alejandro Eder, ratificar el modelo que le quiere brindar a la ciudad
  con los procedimientos ambientales, los cuales permitan dejar atrás
  habitar en una ciudad sucia, anárquica y contaminante, dando un giro,
  con mirada al cambio para realzar a Cali, haciéndola más sostenible y
  bella.`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <InstagramEmbed
          url="https://www.instagram.com/p/C25b0ygATVB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
          width={330}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          El concejal Rodrigo Salazar proyecta dignificar a Cali, mediante la
          soberanía en la operación del servicio de aseo
        </h1>
        <h5 className={style.Fecha}>Enero 15 del 2024</h5>
        <ExpandableText text={Notice1} limit={500} />
        <p className={style.reportero}>Elaboró Dufay Ovalles</p>
      </div>
    </section>
  );
};

export default Semana1;
