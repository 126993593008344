import React, { useEffect, useRef } from "react";
import style from "./lettershine.module.css";

interface LettershineProps {
  parrafo: string;
}

const Lettershine: React.FC<LettershineProps> = ({ parrafo }) => {
  const lettersContainerRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const LetrasElement = lettersContainerRef.current;
    if (!LetrasElement) return;

    const LetrasTexto = LetrasElement.textContent || "";
    LetrasElement.innerHTML = "";

    const spans = LetrasTexto.split("").map((char, index) => {
      const span = document.createElement("span");
      span.innerHTML = char;
      LetrasElement.appendChild(span);
      return span;
    });

    const handleScroll = () => {
      const scroll = window.scrollY * 20;
      spans.forEach((span, index) => {
        if (scroll >= (index + 1) * 10) {
          span.classList.add(style.show);
        } else {
          span.classList.remove(style.show);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      
      <p ref={lettersContainerRef} className={style.lettersTexto}>{parrafo}</p>
    </>
  );
};

export default Lettershine;
