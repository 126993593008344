import React, { useState } from "react";
import style from "../semilla.module.css";

interface ExpandableTextProps {
  text: string;
  limit?: number;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  limit = 100,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded ? text : text.substring(0, limit) + "...";
  const displayedTextWithBreaks = displayedText
    .split("\n")
    .map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    ));

  return (
    <div className={style.NoticeBoxTexto}>
      <p className={style.Notice}>{displayedTextWithBreaks}</p>
      <button className={style.NoticeBottomMore} onClick={toggleExpansion}>
        {isExpanded ? "Ver menos" : "Ver más"}
      </button>
    </div>
  );
};

export default ExpandableText;
