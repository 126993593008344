import style from "../blog.module.css";
import foto from "../../../assets/blog/Marzo-17-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana6: React.FC = () => {
  const Notice1 = `Con el objetivo de visibilizar ante la Alcaldía Distrital de Cali y organismos de protección ambiental como el DAGMA, la grave problemática de presencia de residuos sólidos en la riviera del río Cali, el concejal del Partido Alianza Verde Rodrigo Salazar realizó una brigada de limpieza.
  A raíz de denuncias ciudadanas y de medios de comunicación en redes sociales sobre vertimientos de aguas residuales y de basuras en diferentes puntos del río Cali, la curul verde El Cambio es con Vos, decidió tomar acción y hacerle frente a lo mencionado, junto a ciudadanos de diferentes comunas de Cali se movilizaron en una jornada de aseo que permitió recolectar alrededor de 30 kilos de residuos sólidos de toda clase como botellas plásticas, vidrios, empaques, recipientes de comida, entre otros.
  Las zonas intervenidas fueron desde el parque Ortiz hasta las inmediaciones del Club Colombia, en donde se evidenció que los habitantes de calle son una población que está contribuyendo altamente en la proliferación y mala disposición de las basuras en la ciudad.
  “Desde la curul verde queremos hacer pedagogía en la importancia de proteger nuestras fuentes hídricas, recuperar el río Cali es nuestra prioridad, por eso, le solicitamos al gobierno distrital que adelante todos los esfuerzos institucionales para no abandonar este río y todos los 7 ríos que componen nuestra ciudad, vamos por una Cali ambiental y bella”, sostuvo el concejal Rodrigo Salazar.
  La “gran limpiaton” motivó a caleñas y caleños preocupados por el bienestar del medio ambiente a sumarse a la causa, quienes con bolsas, escobas, guantes y hasta rastrillo en mano, ayudaron a recolectar y disponer los residuos, demostrando el amor que le tienen a la ciudad y sus deseos de verla limpia y bonita.
  “Me ha gustado mucho estar aquí porque más que aceptar la invitación del doctor Salazar, es apostarle a mejorar las condiciones del río Cali y sus alrededores, creo que es tarea de todos cuidar el entorno donde vivimos”, compartió Claudia Lesmes, asistente al evento.
  Esta jornada bajo la arenga de “Cali es bella si participas en ella”, fue una acción de llamamiento a las autoridades distritales, encabezada por el alcalde Alejandro Eder, a organismos como el Dagma y Secretaría de Bienestar Social para que diseñen una estrategia de atención a personas en condición de vulnerabilidad, que tenga su respaldo dentro del Plan Distrital de Desarrollo, más ahora que Cali fue designada como sede del COP-16 se hace mucho más pertinente el fortalecimiento de medidas de protección y mitigación ambiental.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          “Cali es bella si participas en ella”, exitosa limpiaton recolectó 30
          kilos de residuos en el río Cali
        </h1>
        <h5 className={style.Fecha}>Marzo 17 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana6;
