import style from "../blog.module.css";
import foto from "../../../assets/blog/Marzo-10-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana5: React.FC = () => {
  const Notice1 = `Con el objetivo de visibilizar las acciones medioambientales que se desarrollan en la Huerta Madre del barrio Calima, líderes del sector de la comuna 4 junto con el concejal de Partido Alianza Verde Rodrigo Salazar, llevaron a cabo la Primer Ecoferia de la Biodiversidad. 
  Una de las actividades principales fue el conversatorio sobre bosques urbanos, ecobarrios y huertas ecológicas, este contó con la participación de expertos panelistas en preservación medioambiental y seguridad alimentaria, como Daniel Salazar, docente universitario, Carlos Patiño, agrónomo de la org. Ciudades y Ríos, Mauricio Sanchez, Fundación Casa de las Burbujas y Eco barrio San Antonio y Rene Mora, Instructor en producción ecológica del SENA Palmira.
  “Este tipo de espacios permite un reconocimiento frente a lo que se está haciendo como comunidad para el fortalecimiento de la agricultura urbana y tejido social, a partir de las huertas urbanas y crecimiento de este contexto ecológico urbano de cara a las nuevas generaciones”, expresó Daniel Salazar.  
  Otro gran atractivo fue la eco feria, donde alrededor de 50 diferentes emprendimientos sostenibles se dieron cita a lo largo del sendero, dando a conocer sus productos y servicios, entre ellos, café orgánico, jabones artesanales libres de químicos, artesanías, abonos orgánicos, transformación creativa de textiles, plantas ornamentales, mercado y alimentación natural, entre otros a los más de x visitantes que disfrutaron del hermoso paisaje del corredor verde ubicado en la carrera 9na con calle 68.
  “Tenemos un emprendimiento familiar, la granja de Pili, manejamos una agricultura regenerativa en el corregimiento de La Buitrera, estamos con un sistema de acuaponia, estas ferias son importantes porque se mueve la economía local”, compartió Pilar Acosta una de las expositoras. 
  Actualmente en el Distrito de Santiago de Cali existen 700 huertas urbanas activas, algunas incluso cuentan con reconocimientos nacionales e internacionales, convirtiéndose en estrategias pedagógicas exitosas en los territorios de manejo óptimo del espacio público, zonas verdes, redescubrimiento de saberes ancestrales y gestión de paz urbana en los barrios.
  “En el hermoso sendero de la Huerta Madre Calima, junto con organizaciones ambientales desarrollamos la 1er Eco feria de la Biodiversidad, con emprendimientos sostenibles y un conversatorio donde planteamos temáticas como huertas urbanas, ecobarrios y bosques urbanos, son procesos comunitarios que debemos ir posicionando, más ahora que tenemos el COP-16 es la oportunidad para plantear propuestas concretas en camino a un diálogo con el Estado, con cooperación internacional y organizaciones que le apuestan a mundo ambientalmente sostenible.”
  Estos espacios han demostrado ser de gran importancia por los múltiples beneficios que aportan a las comunidades, por eso, su implementación dentro del nuevo Plan de Desarrollo de la Alcaldía Distrital de Cali debe quedar asegurada, que se incluya indicadores y metas para fortalecer las mismas. 
  “Estoy feliz de estar aquí, me enorgullece la labor de esta comunidad en Calima y por los emprendimientos diversos de las mujeres presentes”, expresó Yolanda Acosta, visitante.
  En el marco del COP16, evento de Biodiversidad más importante del mundo que se llevará a cabo en Cali, estos encuentros organizados por las comunidades y organizaciones ambientales le apuestan a la reflexión sobre cómo la implementación de bosques y huertas urbanas en Cali, son un mecanismo de protección y mitigación frente al cambio climático, además de aportar a la seguridad alimentaria.
  `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="FotoEscombros"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Así vivimos la primer Ecoferia de la Biodiversidad en el barrio Calima
        </h1>
        <h5 className={style.Fecha}>Marzo 10 del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana5;
