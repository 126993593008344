import style from "../blog.module.css";
import foto from "../../../assets/blog/Mayo-2024.jpg";
import ExpandableText from "../TextoExpandible";

const Semana10: React.FC = () => {
  const Notice1 = `Tras la sustentación de los indicadores y metas del Plan de Desarrollo ‘Cali, capital pacífica de Colombia’ por parte de la Secretaría de Cultura, el concejal del Partido Alianza Verde, Rodrigo Salazar, presentó en su ponencia los puntos desfavorables frente a uno de los proyectos presentados por el organismo. 

  Se trata del Centro Ferial Multipropósito, un centro de eventos de gran formato que la Secretaría de Cultura plantea construir en el antiguo lote de la rueda (frente al centro comercial Cosmocentro), el cual tiene un área 83.000 metros cuadrados y está ubicado en el barrio El Lido, entre las calles 2 A y 5 y las carreras 48 y 50. 
  
  En este mismo predio, la Secretaría del Deporte y la Recreación también propuso construir un complejo deportivo con escenarios “de alto impacto para la ciudad”, entre los que se destacan canchas de baloncesto, racquetball, squash y pádel. 
  
  Para Salazar, ninguno de los proyectos planteados le apuesta al incremento de espacio público efectivo para los ciudadanos, ni garantizan la conservación de fauna y flora de la ciudad. En su lugar, en el antiguo lote de la rueda, que actualmente está bajo tutela por parte de la Sociedad de Activos Especiales (SAE), se debe construir un gran ecoparque que puede costar alrededor de $70.000 de pesos. Esta obra no solo garantiza la conservación de los entornos naturales, sino que además aportaría a la transformación de Cali.
  
  “Las infraestructuras planteadas tanto por las secretarías de Cultura y Deporte chocan con el propósito del ecoparque, no cuentan con un debido sustento técnico, no aumentan la creación de espacio público efectivo ni, mucho menos, tienen propósitos ambientales y de conservación”, dijo el cabildante. 
  
  Añadió que “tampoco son una solución efectiva a la lucha que, por años, han tenido los habitantes de la comuna 19 frente al ruido generado por el uso comercial que se le ha dado a dicho lote con eventos como la rueda o la ‘Carpa de la 50’, sin contar las múltiples quejas relacionadas a la contaminación ambiental producto del mal manejo de residuos por parte de algunos organizadores de eventos en ese lugar”, manifestó. 
  
  Puntualmente, sobre el proyecto del Centro Ferial Multipropósito propuesto por la Secretaría de Cultura, Salazar expuso que el mismo “constituirá una mole de cemento sobre la Calle 5 y cortaría el corredor natural entre la Cordillera Occidental y el resto de la ciudad, lo que generaría menores corrientes de aire y mayores islas de calor en el sur de Cali”. 
  
  “La obra representaría una barrera para el tránsito de la avifauna que se moviliza en la zona de influencia del corredor biológico del río Cañaveralejo, desde su nacimiento en la Cordillera Occidental (en el Parque Nacional Natural Farallones de Cali), hasta su desembocadura en el canal CVC, y posteriormente, en el río Cauca”, subrayó.
  En cambio, Cali debe apostarle a proyectos con vocación ambiental y ecológica, que aporten espacio público verde. “No hay que apoyar moles de cemento que privaticen el uso del espacio público y rompan los corredores ecológicos de la ciudad”, añadió.
  Asimismo, Salazar aseguró que la capacidad de las vías del sector no es suficiente para soportar mayor cantidad de tráfico, toda vez que allí convergen otras infraestructuras comerciales y deportivas como el Arena Cañaveralejo, los centros comerciales Cosmocentro y Mall Plaza y la Unidad Deportiva Alberto Galindo. 
  
  “Tanto el Centro Ferial Multipropósito como el complejo deportivo propuesto por la Secretaría del Deporte generarían una duplicidad en su vocación con otros escenarios de la zona como el Arena Cañaveralejo, el Coliseo El Pueblo o la misma Unidad Deportiva Alberto Galindo, que  son espacios multipropósito donde es posible realizar desde conciertos privados, festivales de gran envergadura como el Petronio Álvarez, hasta eventos deportivos de talla internacional”, comentó. 
  
  Para el cabildante, el oriente de Cali, que carece actualmente de infraestructura para eventos deportivos y culturales, es el sector de la ciudad donde se deberían realizar dichas obras, que permitan brindar oportunidades de desarrollo y accesibilidad para miles de jóvenes y organizaciones culturales y deportivas que necesitan dotaciones y capacidad instalada para construir un proyecto de vida sostenible.
  
  “Esta situación hace ver a la Administración en un ejercicio de improvisación, que genera desconfianza en la ciudadanía. Invitamos al equipo de Planeación a que revise cómo se están presentando los proyectos estratégicos en este Plan de Desarrollo y cuáles son los verdaderos compromisos de este gobierno”, concluyó.
   `;

  return (
    <section className={style.Noticia}>
      <div className={style.FotoMedios}>
        <img
          className={style.FotoImgMedios}
          src={foto}
          alt="Foto"
          draggable={false}
        />
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Desde el Concejo de Cali se cuestionó obra que Alcaldía plantea
          construir en antiguo lote de la rueda
        </h1>
        <h5 className={style.Fecha}>Mayo del 2024</h5>
        <ExpandableText text={Notice1} limit={400} />
        <p className={style.reportero}>
          Oficina de prensa concejal Rodrigo Salazar{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana10;
