import style from "./whatsappbutton.module.css";

const WhatsappButton: React.FC = () => {
  const handleClick = () => {
    const phoneNumber = '+573115429203'; // Reemplaza esto con el número de teléfono que desees
    const message = 'Hola, Quiero hacer parte del cambio'; // Puedes personalizar el mensaje

    window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <div className={style.whatsappbutton} onClick={handleClick}>
      <div className={style.alertshow}><p className={style.alertshowname}>Estamos en linea!</p></div>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
      />
      
    </div>
  );
};

export default WhatsappButton;
